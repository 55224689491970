.team-member {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;

        .team-member--img-wrap {
            display: block;

            @include mq('>sm') {
                @include grid--span((ratio: 1/3));
            }
        }

        .team-member--description {
            @include mq('>sm') {
                @include grid--span((ratio: 2/3));
            }
        }

        .team-member--img {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 24px;

            @include mq('>sm') {
                margin-bottom: 0;
            }
        }

        .team-member--name {
            margin-bottom: 12px;
            color: $c--brand-ink;
        }

        .team-member--lead {
            @include type--copy-large;
            color: $c--monochrome-dark-gray;
            margin-bottom: 12px;
        }

        .team-member--text {
            @include type--copy;
            color: $c--monochrome-gray;
        }

        .team-member--cta {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            width: 100%;
            margin-top: 8px;
        }
    }

    // Facets

    // States
}
