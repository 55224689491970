.absence-form-element {
    // Vars

    // Support

    // Module

    // Facets
    & {
        // Range
        &.absence-form-element__range {
            .absence-form-element--items {
                display: flex;
                flex-flow: row nowrap;
            }

            .absence-form-element--item {
                @include grid--span((ratio: 1/1));
            }

            .absence-form-element--errors {
                padding: 24px;
            }
        }

        // Weekly
        &.absence-form-element__weekly {
            @include grid--root((row: 'items', cols: ('item')));
            @include grid--space((gutter: (48px 48px), row: 'items', cols: ('item')));

            .absence-form-element--body {
                padding: 24px;
            }

            .absence-form-element--items {
                display: flex;
                flex-flow: row nowrap;
            }

            .absence-form-element--item {
                @include grid--span((ratio: 1/3));
                @include grid--span((ratio: 1/1, mq: '<=md'));
            }

            .absence-form-element--errors {
                padding: 24px 0 0;
            }
        }
    }

    // States
}
