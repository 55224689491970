.actions-panel {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'entries', cols: ('entry')));
        @include grid--space((gutter: (8px 8px), row: 'entries', cols: ('entry')));
        overflow: hidden;
    }

    // Facets
    & {
        // Base
        &.actions-panel__base {
            padding: 0 0 24px;
            margin: 0 16px;
        }

        // Spaced
        &.actions-panel__spaced {
            padding: 24px 0;
        }

        // Dashboard
        &.actions-panel__dashboard {
            @include grid--root((row: 'entries', cols: ('entry')));
            @include grid--space((gutter: (20px 24px), row: 'entries', cols: ('entry')));
            margin-top: 36px;
        }

        // Dashboard section
        &.actions-panel__dashboard-section {
            padding: 0 0 24px;
            margin: 0 24px;
        }

        // Overlay form (snippets and vouchers)
        &.actions-panel__overlay-form {
            @include grid--root((row: 'entries', cols: ('entry')));
            @include grid--space((gutter: (12px 24px), row: 'entries', cols: ('entry')));

            padding: 20px 24px;
        }

        // Dashboard table
        &.actions-panel__dashboard-table {
            width: 100%;
            margin-top: 24px;
        }

        // Alignments
        &.actions-panel__alignment-start {
            .actions-panel--entries {
                justify-content: flex-start;
            }
        }

        &.actions-panel__alignment-center {
            .actions-panel--entries {
                justify-content: center;
            }
        }

        &.actions-panel__alignment-end {
            .actions-panel--entries {
                justify-content: flex-end;
            }
        }
    }

    // States
}
