.video {
    // Vars

    // Support

    // Module
    & {
        & {
            @include cms--padded-plugin();
        }

        .video--container {
            position: relative;
            height: 0;
            padding-top: 2 / 3 * 100%;
        }

        .video--iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }

    // Facets

    // States
}
