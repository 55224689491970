.icd-10-suggestions {
    $c--hover: #cce0eb;
    $c--box-shadow: #bebfc17a;
    $sz--padding: 8px;

    & {
        font-size: $fz--xx-small;
        background-color: $c--monochrome-light-gray;
        box-shadow: 0 4px 4px 0 $c--box-shadow inset;
        overflow: auto;

        & .icd-10-suggestions--table {
            width: 100%;
        }

        & .icd-10-suggestions--tr {
            width: 100%;

            &:first-child .icd-10-suggestions--td {
                padding-top: $sz--padding;
            }

            &:last-child .icd-10-suggestions--td {
                padding-bottom: $sz--padding;
            }

            &.icd-10-suggestions--tr__selectable:hover {
                background-color: $c--hover;
                cursor: pointer;
            }
        }

        & .icd-10-suggestions--td {
            padding: $sz--padding;

            &.icd-10-suggestions--td__code {
                width: 10%;
            }
        }

        @media (max-width: 767px) {
            max-height: 60vh;
        }

        @media (min-width: 768px) and (max-width: 992px) {
            max-height: 30vh;
        }

        @media (min-width: 993px) {
            max-height: 50vh;
        }
    }
}

.icd-10-suggestions__not-found {
    & .icd-10-suggestions__not-found--p {
        margin-top: 10px;
        font-size: $fz--xx-small;
    }
}
