.teaser {
    // Vars

    // Support

    // Module
    & {
        color: $c--brand-ink;

        .teaser--image {
            max-width: 100%;
        }

        .teaser--content {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 60px;
        }

        .teaser--title {
            @include type--copy-large;
            flex: 0 1 100%;
            margin-top: 24px;

            @include mq('<=sm') {
                margin-top: 12px;
            }
        }

        .teaser--btn {
            flex: 0 1 auto;
            margin-top: 20px;
        }
    }

    // Facets

    // States
}
