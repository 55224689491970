.nav-links {
    // Vars
    $sz--item-border-height: 3px;
    $sz--lang-nav-width: 80px;

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: (4px 12px), row: 'items', cols: ('item')));

        .nav-links--items {
            flex-flow: row wrap;
            justify-content: flex-start;
        }

        .nav-links--item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .nav-links--link {
            @include ff--base-semi-bold;
            @include type--copy-small;
            color: $c--monochrome-gray;
            transition: color $trs--base;
            cursor: pointer;
            display: block;
            width: 100%;

            @include has-focus(true) {
                color: $c--brand-light-ink;
            }
        }

        .nav-links--text {
            position: relative;
            display: inline-block;
            padding: 0 0 2px;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: $sz--item-border-height;
                background-color: $c--brand-ink;
                opacity: 0;

                transform: translateY(-$sz--item-border-height);
                transition: transition--props($trs--base, opacity, transform);
            }
        }
    }

    // Facets
    & {
        // Link is active
        & {
            .nav-links--link.nav-links--link__is-active {
                @include has-focus(default) {
                    color: $c--brand-ink;
                    cursor: default;

                    .nav-links--text::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // States
}
