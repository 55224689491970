/// Field styles
///
@mixin form-field--cui-checkbox() {
    // Vars
    $sz--checkbox-icon: 24px;
    $sz--checkbox-border-radius: 4px;

    // Module
    & {
        .form-field--input {
            ~ .form-field--label-wrap {
                .form-field--box {
                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--checkbox-border-radius;
                    }
                }

                .form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: ($sz--checkbox-icon / -2);
                    margin-top: ($sz--checkbox-icon / -2);
                    width: $sz--checkbox-icon;
                    height: $sz--checkbox-icon;
                }
            }
        }
    }

    // States
}
