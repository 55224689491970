.health-insurance-overlay-frame {
    // Vars

    // Support

    // Module

    & {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .health-insurance-overlay-frame--header {
        position: relative;
        display: flex;
        align-items: center;
        background: $c--monochrome-white;
        border-bottom: 1px solid $c--monochrome-light-gray;
        border-radius: $sz--border-radius-base $sz--border-radius-base 0 0;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        padding: 15px;
        width: 100%;
    }

    .health-insurance-overlay-frame--navigation-button {
        color: $c--brand-ink;
        cursor: pointer;
        flex-grow: 0;
        flex-shrink: 0;

        @include mq('<=sm') {
            transform: scale(0.9);
        }
    }

    .health-insurance-overlay-frame--title {
        @include type--h2;
        color: $c--brand-ink;
        padding: 0;
        text-align: center;

        @include at-root(html, '.edge') {
            border-radius: 0;
        }

        @include mq('<=sm') {
            @include type--h3;
        }
    }

    .health-insurance-overlay-frame--content {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: $c--monochrome-white;
        border-radius: 0 0 $sz--border-radius-base $sz--border-radius-base;

        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;

        overflow: hidden;
        height: 100vh;

        @include mq('<=sm') {
            padding: 0 1.6rem 1.6rem;
        }
    }

    .overlay-modal--footer {
        $sz--bg-offset: ($sz--border-width-base * 2);

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-image: linear-gradient(
            to top,
            transparent 0,
            transparent $sz--bg-offset,
            $c--monochrome-white ($sz--bg-offset + 1px),
            $c--monochrome-white 100%
        );
        border-top: 1px solid $c--brand-ink;
    }

    .overlay-modal--btn {
        flex: 0 1 50%;
        overflow: hidden;
        box-shadow: none;

        &:first-child,
        &:last-child {
            &::before {
                content: none;
            }

            &,
            &::before,
            &::after {
                border-radius: 0;
            }
        }

        &:only-child {
            flex: 0 1 100%;
        }

        &:first-child {
            &,
            &::before,
            &::after {
                border-bottom-left-radius: $sz--border-radius-base;

                @include at-root(html, '.edge') {
                    border-radius: 0;
                }
            }
        }

        &:last-child {
            &,
            &::before,
            &::after {
                border-bottom-right-radius: $sz--border-radius-base;

                @include at-root(html, '.edge') {
                    border-radius: 0;
                }
            }
        }

        @media (max-width: 400px) {
            font-size: 16px;
        }
    }
}
