.cui-message {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        transition: opacity $trs--base;

        .cui-message--wrapper {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            //background-color: rgba($c--monochrome-white, 0.1);
        }

        .cui-message--bubble {
            left: 0;
            width: 100%;
        }
    }

    // Facets
    & {
        //
    }

    // States
    & {
        //
        &.cui-message__not-injected.cui-message__not-injecting {
            .cui-message--wrapper {
                justify-content: flex-end;
            }
        }

        //
        &.cui-message__not-injected.cui-message__is-injecting {
            .cui-message--bubble {
                position: absolute;
            }

            .cui-message--bubble.cui-message--bubble__not-moving {
                bottom: 0;
            }

            .cui-message--bubble.cui-message--bubble__is-moving {
                top: 0;
            }
        }

        //
        &.cui-message__not-injected {
            flex-grow: 1;

            .cui-message--wrapper {
                flex-grow: 1;
            }
        }

        //
        &.cui-message__was-injected {
            flex-grow: 0;

            .cui-message--wrapper {
                flex-grow: 0;
                justify-content: flex-start;
            }
        }

        // Other messages when a message is being edited
        &.cui-message__manager-is-editing-completed-messages {
            opacity: 0.4;
        }

        // Edit mode of an already given answer
        &.cui-message__is-editing-group {
            opacity: 1;
        }
    }
}
