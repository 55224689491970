.doctor-card {
    // Vars

    // Support

    // Module
    & {
        flex-flow: row nowrap;
        align-items: stretch;

        &,
        .doctor-card--wrapper {
            display: flex;
            flex: 0 1 100%;
            overflow: hidden;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }

        .doctor-card--wrapper {
            flex-flow: column nowrap;
        }

        .doctor-card--portrait {
            position: relative;
        }

        .doctor-card--img-link-wrap {
            padding-top: 100%;
        }

        .doctor-card--img-link {
            @include overlay();
            display: block;
            overflow: hidden;
        }

        .doctor-card--img {
            transition: transform $trs--base;
            display: block;
            width: 100%;
        }

        .doctor-card--description {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            flex: 1 0 auto;
            padding: 12px 16px;
            background-color: $c--monochrome-white;
        }

        .doctor-card--name-link {
            display: block;
            transition: $trs--common-props;
        }

        .doctor-card--name {
            @include type--copy;
        }

        .doctor-card--lines {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
        }

        .doctor-card--line {
            &.doctor-card--line__with-icon {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                flex: 0 0 auto;
            }

            &.doctor-card--line__detail-hint {
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-end;
                flex: 0 1 100%;

                @include has-support((touchevents: false)) {
                    display: none;
                }

                @include grid--fix-ie-flex-basis() {
                    height: auto;
                }
            }

            ~ .doctor-card--line {
                margin-top: 4px;
            }
        }

        .doctor-card--line-text {
            @include type--copy-x-small;
            color: $c--monochrome-gray;
            transform: translateY(1px); // NOTE: Visual correction

            &.doctor-card--line-text__languages {
                text-transform: uppercase;
            }
        }

        .doctor-card--line-icon {
            color: $c--brand-ink;
            margin-right: 4px;
        }

        .doctor-card--cta {
            position: relative;
            margin-top: 1px;
        }

        .doctor-card--cta-btn {
            display: block;
            width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            @include at-root(html, '.edge') {
                &, &::before, &::after {
                    border-radius: 0;
                }
            }
        }
    }

    // Facets
    & {
        &.doctor-card__has-detail-link {
            // NOTE: Active if (touchevents == false) and (hover == true)
            &.doctor-card__has-hover {
                @include has-support((touchevents: false)) {
                    .doctor-card--img {
                        transform: scale(1.05);
                    }

                    .doctor-card--name-link {
                        color: $c--brand-dark-ink;
                    }
                }
            }

            @include has-support((touchevents: true)) {
                .doctor-card--img-link:active {
                    .doctor-card--img {
                        transform: scale(1.05);
                    }
                }

                .doctor-card--name-link:active {
                    color: $c--brand-dark-ink;
                }
            }
        }

        &.doctor-card__home {
            @include mq('<=sm') {
                overflow: hidden;
            }

            .doctor-card--description {
                padding: 12px 0 0;
            }
        }

        // Unavailable state for base doctor card
        &.doctor-card__base.doctor-card__is-unavailable {
            opacity: 0.5;

            .doctor-card--cta {
                &::after {
                    @include overlay();
                    content: '';
                    display: block;
                    background-color: $c--monochrome-white;
                    border-bottom-left-radius: $sz--border-radius-base;
                    border-bottom-right-radius: $sz--border-radius-base;
                    background-clip: content-box;
                    padding-bottom: $sz--border-width-base * 2;

                    @include at-root(html, '.edge') {
                        border-radius: 0;
                    }
                }
            }

            .doctor-card--cta-btn {
                visibility: hidden;
            }
        }
    }

    // States
}
