.nav-bar {
    // Vars
    $c--shadow: $c--monochrome-dark-gray;

    // Support

    // Module
    & {
        @include grid--root((row: 'logos', cols: ('logo')));
        @include grid--space((gutter: 40px, row: 'logos', cols: ('logo')));

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $c--monochrome-white;
        box-shadow: 0 3px 10px 0 rgba($c--shadow, 0.2);

        .nav-bar--wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .nav-bar--logos {
            display: flex;
            flex-flow: row nowrap;
            flex: 1 1 auto;
            align-items: center;
            margin-right: 40px;
        }

        .nav-bar--img {
            display: block;
            width: 57px;
            height: 52px;
        }

        .nav-bar--menu {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-self: stretch;
            flex: 0 1 100%;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }
    }

    // Facets
    & {
        // Main
        &.nav-bar__main {
            z-index: z-index('main-nav');
            height: $sz--main-nav-height;

            .nav-bar--wrap {
                height: $sz--main-nav-height;
            }
        }

        // Mobile
        &.nav-bar__mobile {
            z-index: z-index('mobile-nav');
            height: $sz--mobile-nav-height;

            .nav-bar--wrap {
                height: $sz--mobile-nav-height;
            }
        }

        // Dashboard
        &.nav-bar__dashboard {
            z-index: z-index('main-nav');
            height: $sz--dashboard-header-height;

            .nav-bar--logos {
                justify-content: space-between;
                margin-right: 0;
            }

            .nav-bar--wrap {
                height: $sz--dashboard-header-height;
            }
        }
    }

    // States
}
