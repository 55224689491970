.call-to-action {
    // Vars

    // Support

    // Module
    & {
        .call-to-action--bg {
            position: relative;
            padding: 52px 0 60px;

            &::before,
            &::after {
                @include overlay(absolute);
                content: '';
            }

            &::before {
                @include bg--title();
            }

            &::after {
                @include bg--pattern();
            }
        }

        .call-to-action--container {
            z-index: 1;
            position: relative;
        }

        .call-to-action--content {
            color: $c--monochrome-white;
            text-align: center;
        }

        .call-to-action--separator {
            display: inline-block;
            margin: 20px 0 22px;
        }

        .call-to-action--link {
            display: block;
        }
    }

    // Facets

    // States
}
