.title-placeholder {
    // Vars

    // Support

    // Module
    & {
        padding: 90px 0 120px;
        color: $c--monochrome-white;

        @include mq('<=sm') {
            padding: 40px 0;
        }
    }

    // Facets
    & {
        &.title-placeholder__base {
            @include bg--title();
        }

        &.title-placeholder__doctors {
            padding-bottom: 0;
        }

        &.title-placeholder__home {
            padding: 0;
        }
    }

    // States
}
