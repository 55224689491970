.form-field-messages {
    // Vars
    $fz--form-field-message: 1.1rem;
    $lh--form-field-message: 1.82;

    // Support

    // Module
    & {
        position: relative;
        margin-top: 4px;

        .form-field-messages--message {
            display: block;
            font-size: $fz--form-field-message;
            line-height: $lh--form-field-message;

            ~ .form-field-messages--message {
                margin-top: 3px;
            }

            &.form-field-messages--message__info {
                color: $c--signal-info;
            }

            &.form-field-messages--message__error {
                color: $c--signal-error;
            }
        }
    }

    // Facets

    // States
}
