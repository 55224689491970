.ui-overlay {
    // Vars

    // Support

    // Module
    & {
        .ui-overlay--root {
            z-index: z-index('overlay');
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .ui-overlay--backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($c--brand-black-ink, 0.8);
        }

        .ui-overlay--display {
            @include ios-native-scrolling;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .ui-overlay--backdrop-click-area {
            @include overlay();
            z-index: 1;
        }

        .ui-overlay--wrap-outer {
            @include is-selectable(true);
            display: table;
            width: 100%;
            height: 100%;
            table-layout: fixed;
        }

        .ui-overlay--wrap-inner {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .ui-overlay--container {
            z-index: 2;
            position: relative;
            width: 100%;
        }

        .ui-overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: left;
        }
    }

    // Facets
    & {
        // Confirmation
        &.ui-overlay__confirmation {
            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }

        // Toast
        &.ui-overlay__toast {
            .ui-overlay--root {
                pointer-events: none;
            }

            .ui-overlay--display {
                overflow: visible;
            }

            .ui-overlay--wrap-inner {
                vertical-align: top;
                padding-top: $sz--main-nav-height + 16px;
            }

            .ui-overlay--backdrop {
                display: none;
            }

            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }

        // Dashboard sidebar
        &.ui-overlay__dashboard-sidebar {
            .ui-overlay--root {
                pointer-events: none;
                z-index: z-index('overlay') - 2;
            }

            .ui-overlay--display {
                @include mq('>lg') {
                    overflow: visible;
                }

                @include mq('<=lg') {
                    overflow: hidden;
                }
            }

            .ui-overlay--wrap-outer {
                display: block;
            }

            .ui-overlay--wrap-inner {
                display: block;
            }

            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                height: 100%;
            }

            .ui-overlay--backdrop {
                @include mq('>lg') {
                    display: none;
                }

                @include mq('<=lg') {
                    top: $sz--dashboard-header-height;
                    background-color: transparent;
                }
            }

            .ui-overlay--backdrop-click-area {
                @include mq('>lg') {
                    display: none;
                }

                @include mq('<=lg') {
                    pointer-events: auto;
                    top: $sz--dashboard-header-height;
                    bottom: 0;
                    height: auto;
                }
            }

            .ui-overlay--revealer {
                justify-content: flex-end;
            }
        }

        // Cui overview
        &.ui-overlay__cui-overview {
            .ui-overlay--root {
                z-index: 1;
            }

            .ui-overlay--wrap-outer {
                @include mq('>#{$bp--mobile}') {
                    padding-top: $sz--main-nav-height;
                }

                @include mq('<=#{$bp--mobile}') {
                    padding-top: $sz--mobile-nav-height;
                }
            }

            .ui-overlay--wrap-inner {
                vertical-align: top;
            }

            .ui-overlay--backdrop {
                background-color: $c--monochrome-white;
            }

            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }

        // Mobile nav
        &.ui-overlay__mobile-nav {
            @include overlay(fixed);
            pointer-events: none;
            z-index: z-index('overlay-mobile-nav');
            perspective: 1200px;
            perspective-origin: top;

            .ui-overlay--root {
                pointer-events: auto;
                transform-origin: top center;
            }

            .ui-overlay--wrap-inner {
                padding-top: $sz--mobile-nav-height;
            }

            .ui-overlay--display,
            .ui-overlay--wrap-outer,
            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
            }

            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                flex: 0 1 100%;
            }
        }

        // Image viewer / Body regions
        &.ui-overlay__image-viewer,
        &.ui-overlay__body-regions {
            @include overlay(fixed);
            z-index: z-index('overlay') + 1;

            .ui-overlay--backdrop-click-area {
                pointer-events: auto;
            }

            .ui-overlay--display,
            .ui-overlay--wrap-outer,
            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
            }

            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                flex: 0 1 100%;
            }
        }

        // Body regions
        &.ui-overlay__body-regions {
            pointer-events: none;

            .ui-overlay--display {
                overflow: hidden;
            }
        }

        // Tooltip
        &.ui-overlay__tooltip {
            .ui-overlay--root {
                pointer-events: none;
                position: absolute;
            }

            .ui-overlay--display {
                overflow: visible;
            }

            .ui-overlay--wrap-inner {
                vertical-align: top;
            }

            .ui-overlay--backdrop {
                display: none;
            }

            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }

        // Impage viewer
        &.ui-overlay__image-viewer {
            pointer-events: none;
        }

        // Snippets form
        &.ui-overlay__snippets-form {
            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }

        // Snippets chooser
        &.ui-overlay__snippets-chooser {
            z-index: z-index('overlay') - 2;

            .ui-overlay--display,
            .ui-overlay--wrap-outer,
            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
            }

            .ui-overlay--wrap-inner,
            .ui-overlay--container,
            .ui-overlay--revealer {
                flex: 0 1 100%;
            }

            .ui-overlay--backdrop {
                top: $sz--dashboard-header-height;
                background-color: transparent;
            }

            .ui-overlay--backdrop-click-area {
                pointer-events: auto;
                top: $sz--dashboard-header-height;
                bottom: 0;
                height: auto;
            }

            .ui-overlay--revealer {
                justify-content: flex-end;
            }
        }
    }

    // States
}
