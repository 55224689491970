.health-insurance-overlay-insurance-search {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex: 1;
            flex-direction: column;
            max-height: 100%;
        }

        .health-insurance-overlay-insurance-search--box {
            display: flex;
            padding: 0 15px;
        }

        .health-insurance-overlay-insurance-search--list {
            overflow-y: scroll;
            flex: 1 1;
            padding: 15px;
        }

        .health-insurance-overlay-insurance-search--list-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 7px;

            &:hover {
                background-color: darken($c--monochrome-light-gray, 1%);
            }

            &::before {
                background: none;
            }
        }

        .health-insurance-overlay-insurance-search--body {
            color: #333333;
            font-size: 18px;
            font-weight: 400;
            padding: 15px;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }

        .health-insurance-overlay-insurance-search--info {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            align-items: center;
            text-align: center;
        }

        .health-insurance-overlay-insurance-search--public-insurance-name {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            padding-bottom: 10px;
            color: #00629b;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }

        .health-insurance-overlay-insurance-search--public-insurance-description {
            font-size: 16px;
            font-style: normal;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }

        .health-insurance-overlay-insurance-search--item-media {
            padding-bottom: 10px;
        }
    }

    // Facets

    // States
}
