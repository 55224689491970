.image-widget {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'actions', cols: ('action')));
        @include grid--space((gutter: (8px 8px), row: 'actions', cols: ('action')));

        .image-widget--field-section {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: stretch;
            flex: 0 1 50%;

            @include mq('<=sm') {
                flex: 0 1 100%;
                margin-top: 16px;
            }
        }

        .image-widget--content {
            display: flex;
            flex-flow: row nowrap;

            @include mq('<=sm') {
                flex-flow: row wrap;
            }
        }

        .image-widget--image-section {
            flex: 0 1 50%;
            overflow: hidden;
            margin-right: 16px;

            @include mq('<=sm') {
                flex: 0 1 100%;
                margin-right: 0;
            }
        }

        .image-widget--image-wrap {
            position: relative;
            width: 100%;
            padding-top: 100%;
            background-color: $c--monochrome-light-gray;
        }

        .image-widget--image-positionner {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
        }

        .image-widget--image-el {
            position: relative;
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }

        .image-widget--actions {
            position: absolute;
            top: 0;
            right: 0;
            flex-flow: column nowrap;
            padding: 16px;
        }

        .image-widget--action-icon {
            width: 100%;
            height: 100%;
            color: currentColor;
        }
    }

    // Facets

    // States
    & {
        .image-widget--image-wrap {
            &:hover {
                .image-widget--action {
                    opacity: 1;
                }
            }
        }
    }
}
