.page.page__type-dashboard {
    // Vars

    // Support

    // Module
    & {
        padding-bottom: 0;

        .page--main {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
        }

        .page--sections {
            display: flex;
            flex-flow: row nowrap;
            flex: 1 0 auto;
            padding-top: $sz--dashboard-header-height;
        }

        .page--main-section {
            flex: 0 1 100%;
            padding-bottom: 40px;
            overflow: hidden;
        }

        .page--aside-section {
            @include mq('>lg') {
                flex: 0 0 $sz--dashboard-sidebar-width;
            }
        }

        .page--actions {
            margin-top: 24px;
        }
    }

    // Facets

    // States
}
