/// Field styles
///
@mixin form-field--as-button-select() {
    // Vars


    // Module
    & {
        .form-field--input-container {
            @include btn--shadowed-base(2px, $c--brand-dark-ink, $sz--border-width-base);
            @include ff--base-semi-bold;
            @include type--copy;
            border: $sz--border-width-base solid transparent;
            border-radius: $sz--border-radius-base;
            transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);

            &, &::after {
                @include btn--primary-inv-base();
            }
        }

        .form-field--input {
            display: block;
            width: 100%;
            border: none;
            font-size: $fz--medium;
            cursor: pointer;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--as-button-select-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--as-button-select-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--as-button-select-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--as-button-select-error();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--as-button-select-empty();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--as-button-select-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--as-button-select-base() {
    .form-field--input {
        cursor: pointer;

        @include placeholder() {
            color: $c--monochrome-silver;
        }
    }

    .form-field--action.form-field--action__arrow {
        pointer-events: none;
    }
}

/// Focus
///
@mixin form-field--as-button-select-focus() {
    .form-field--input-container {
        @include btn--shadowed-active($sz--border-width-base);

        &, &::after {
            @include btn--primary-inv-focus();
        }
    }

    .form-field--title-label {
        color: $c--signal-info;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--brand-ink;
    }
}

/// Disabled
///
@mixin form-field--as-button-select-disabled() {
    .form-field--input-container {
        @include btn--shadowed-active($sz--border-width-base);

        &, &::after {
            @include btn--primary-inv-disabled();
            cursor: default;
        }
    }

    .form-field--input {
        cursor: default;
    }

    .form-field--action.form-field--action__arrow {
        color: $c--monochrome-silver;
    }
}

/// Error
///
@mixin form-field--as-button-select-error() {
    .form-field--title-label {
        color: $c--signal-error;
    }
}

/// Empty
///
@mixin form-field--as-button-select-empty() {
    .form-field--input {
        color: $c--monochrome-silver;
    }
}

/// Empty and disabled
///
@mixin form-field--as-button-select-empty-disabled() {
    //
}
