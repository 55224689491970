.new-feature-info-badge {
    // Module
    & {
        .new-feature-info-box {
            display: flex;
            align-items: center;
            color: white;
            border-radius: 25px;
            background: #ffb81c;

            .new-feature-info-text {
                font-size: 10px;
                padding-left: 8px;
                padding-right: 8px;
                //this is used, so that the click event from the button works correctly on GTM (even if you click on the SVG inside of the button)
                pointer-events: none;
            }

            .new-feature-info-icon {
                margin-right: 5px;
                //this is used, so that the click event from the button works correctly on GTM (even if you click on the SVG inside of the button)
                pointer-events: none;
            }
        }
    }
}
