.health-insurance-overlay-insurance-type-selection {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        .health-insurance-overlay-insurance-type-selection--body {
            color: #333333;
            font-size: 18px;
            font-weight: 400;
            padding: 15px 15px 0;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }


        .health-insurance-overlay-insurance-type-selection--list {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            justify-content: center;
            padding-left: 13px;
            padding-right: 13px;
        }

        .health-insurance-overlay-insurance-type-selection--list-item {
            display: flex;
            align-items: center;
            padding: 1.6rem;
            background-color: rgba($c--monochrome-light-gray, 0.5);
            transition: background-color 150ms;
            cursor: pointer;
            user-select: none;
            margin-top: 1.6rem;


            &:hover {
                background-color: darken($c--monochrome-light-gray, 15%);
            }

            @media (max-width: 400px) {
                padding: 10px;
            }
        }


        .health-insurance-overlay-insurance-type-selection--item-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 1;
        }

        .health-insurance-overlay-insurance-type-selection--item-title {
            font-size: 18px;
            font-weight: 600;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }


        .health-insurance-overlay-insurance-type-selection--item-media {
            align-self: center;
            margin-right: 15px;
            vertical-align: middle;
            width: 40px;

            @media (max-width: 400px) {
                margin-right: 7px;
            }
        }
    }

    // Facets

    // States
}
