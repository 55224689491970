.table-col {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
    }

    // Facets
    & {
        &.table-col__align-left {
            justify-content: flex-start;
        }

        &.table-col__align-right {
            justify-content: flex-end;
        }

        &.table-col__align-center {
            justify-content: center;
        }
    }

    // States
}
