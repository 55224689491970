.table-col.table-col__text {
    // Vars
    $fz--table-text: 13px;
    $lh--table-text: 1.92;

    // Support

    // Module
    & {
        padding: 8px 16px;

        .table-col--value {
            @include text-ellipsis;
            display: block;
            font-size: $fz--table-text;
            line-height: $lh--table-text;
        }
    }

    // Facets

    // States
}
