.voucher-modal {
    // Vars

    // Support

    // Module
    & {
        .voucher-modal--container.voucher-modal--container {
            max-width: $sz--voucher-modal-width;

            @include at-root(html, '.ie11') {
                box-sizing: content-box;
                max-width: calc(#{$sz--voucher-modal-width} - 0.1px);

                // stylelint-disable selector-max-universal
                > * {
                    box-sizing: border-box;

                    * {
                        box-sizing: inherit;
                    }
                }
                // stylelint-enable selector-max-universal
            }
        }
    }

    // Facets

    // States
}
