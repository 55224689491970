/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
/// See: `util/z-index.scss`

/// Fonts
/// See: `base/type.scss`

/// Sizes & spacing
/// See: `base/spacing.scss`

/// Misc
///
$bp--mobile: 'md';

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease('in-out-material');
$trs--base: $trs--base-speed $trs--base-fn;

$trs--overlay-speed: 200ms;
$trs--overlay-fn: ease('in-out-material');
$trs--overlay: $trs--overlay-speed $trs--overlay-fn;

$trs--toast-speed: 200ms;
$trs--toast-fn: ease('in-out-material');
$trs--toast-delay: 2000ms;
$trs--toast: $trs--toast-speed $trs--toast-fn $trs--toast-delay;

$trs--dashboard-sidebar-speed: 400ms;
$trs--dashboard-sidebar-fn: ease('in-out-material');
$trs--dashboard-sidebar: $trs--dashboard-sidebar-speed $trs--dashboard-sidebar-fn;

$trs--cui-message-edit-speed: 200ms;
$trs--cui-message-edit-fn: ease('in-out-material');
$trs--cui-message-edit: $trs--cui-message-edit-speed $trs--cui-message-edit-fn;

$trs--cui-message-inject-speed: 600ms;
$trs--cui-message-inject-fn: ease('in-out-quart');
$trs--cui-message-inject-delay: 200ms;
$trs--cui-message-inject: $trs--cui-message-inject-speed $trs--cui-message-inject-fn;

$trs--cui-accordion-speed: 400ms;
$trs--cui-accordion-fn: ease('out-material');
$trs--cui-accordion: $trs--cui-accordion-speed $trs--cui-accordion-fn;

$trs--cui-progress-indicator-speed: 600ms;
$trs--cui-progress-indicator-fn: ease('in-out-material');
$trs--cui-progress-indicator: $trs--cui-progress-indicator-speed $trs--cui-progress-indicator-fn;

$trs--flip-password-toggle-speed: 200ms;
$trs--flip-password-toggle-fn: ease('out-material');
$trs--flip-password-toggle: $trs--flip-password-toggle-speed $trs--flip-password-toggle-fn;

$trs--region-speed: 100ms;
$trs--region-fn: ease('in-out-material');
$trs--region: $trs--region-speed $trs--region-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);
