.cui-message-display-content-readonly {
    // Vars
    display: flex;

    // Support

    // Module
    & {
        padding: ($sz--bubble-padding-y + 1px) $sz--bubble-padding-x ($sz--bubble-padding-y - 1px);

        .cui-message-display-content-readonly--title {
            display: inline;
            text-align: left;
            min-width: 24px;
        }

        .cui-message-display-content-readonly--text {
            display: block;
            text-align: left;
            min-width: 24px;
        }

        .cui-message-display-content-readonly--badge-wrap {
            padding-right: $sz--bubble-padding-x;

            .cui-message-display-content-readonly--badge {
                display: inline-block;
                background-color: $c--brand-dark-ink;
                font-size: $fz--xx-small;
                width: 1.9rem;
                text-align: center;
                color: #9dd0f4;
                border-radius: 50%;
            }
        }

        .cui-message-display-content-readonly--text {
            @include type--copy-small;
        }
    }

    // Facets

    // States
}
