// stylelint-disable plugin/selector-bem-pattern


.cookie-monster {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('cookie-banner');
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: $c--monochrome-white;
            box-shadow: 0 0 8px 8px rgba($c--brand-ink, 0.1);
            max-height: 100vh;
            overflow: auto;

            @include grid--root();

            @each $value in $grid--base-container-sizes {
                @include grid--constrain($value);
            }

            @each $value in $grid--base-spacing {
                @include grid--space($value);
            }
        }

        .cookie-monster--title.cookie-monster--title {
            @include type--h3;

            padding-top: 32px;
            margin-bottom: 8px;
        }

        .cookie-monster--text {
            @include type--copy-x-small;

            margin: 0 0 16px;

            > a {
                color: $c--label-text;
                text-decoration: underline;
            }
        }

        .cookie-monster--btn {
            @include btn--all;
            @include btn--size-small;
            @include type--copy-small;
        }

        .cookie-monster--groups-list {
            margin: 0 0 16px;
        }

        .cookie-monster--group-title {
            cursor: pointer;
            padding: 8px 36px 8px 8px;
            border-bottom: 1px solid rgba($c--monochrome-light-gray, 0.5);
            background: {
                color: rgba($c--monochrome-light-gray, 0.4);
                position: right 10px center;
                repeat: no-repeat;
            }

            @include has-focus(true) {
                background-color: rgba($c--monochrome-light-gray, 0.5);
            }

            /* stylelint-disable string-quotes */
            &[aria-expanded='false'] {
                background-image: url('#{svg--create(16, 16, '<path fill="#000" fill-rule="nonzero" d="M8 8.586l-4-4L2.586 6 8 11.414 13.414 6 12 4.586z"/>')}');
            }

            &[aria-expanded='true'] {
                background-image: url('#{svg--create(16, 16, '<path fill="#000" fill-rule="nonzero" d="M8 7.414l4 4L13.414 10 8 4.586 2.586 10 4 11.414z"/>')}');
            }

            /* stylelint-enable string-quotes */
        }

        .cookie-monster--group-title-text {
            @include type--copy-x-small;
            @include ff--base-semi-bold;
        }

        .cookie-monster--table {
            @include type--copy-x-small;
        }

        .cookie-monster--btn-group {
            display: flex;
            flex: 1 1 100%;
            justify-content: flex-end;
            padding-bottom: 32px;

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .cookie-monster--btn-wrapper {
            > button {
                @include mq('<=sm') {
                    width: 100%;
                }
            }

            ~ .cookie-monster--btn-wrapper {
                @include mq('>sm') {
                    margin-left: 16px;
                }

                @include mq('<=sm') {
                    margin-top: 16px;
                }
            }
        }

        .cookie-monster--group-title {
            display: flex;

            @include mq('>sm') {
                align-items: center;
                justify-content: space-between;
            }

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .cookie-monster--table-label {
            display: block;
            width: 100%;
            padding: 16px;
        }

        .cookie-monster--group-description {
            overflow: auto;
            border-left: 1px solid rgba($c--monochrome-light-gray, 0.25);
            border-right: 1px solid rgba($c--monochrome-light-gray, 0.25);
        }

        .cookie-monster--table {
            width: 100%;

            th {
                padding: 16px;
                background: rgba($c--monochrome-light-gray, 0.25);

                @include ff--base-semi-bold;
            }

            tr:nth-child(even) {
                td {
                    background: rgba($c--monochrome-light-gray, 0.1);
                }
            }
        }

        .cookie-monster--table-cell {
            vertical-align: middle;
            border-bottom: 1px solid rgba($c--monochrome-light-gray, 0.25);

            &:last-of-type {
                text-align: right;
            }

            @include mq('>sm') {
                width: 14%;

                &:nth-child(3), &:nth-child(4), {
                    width: 29%;
                }
            }

            @include mq('<=sm') {
                &:nth-child(2), &:nth-child(3), {
                    display: none;
                }
            }
        }
    }

    // Facets
    .cookie-monster--btn {
        &.cookie-monster--btn__toggle {
            @include btn--simple-text;
        }

        &.cookie-monster--btn__accept-all, {
            @include btn--primary;
        }

        &.cookie-monster--btn__confirm {
            @include btn--secondary;
        }

        &.cookie-monster--btn__accept-all-group-cookies {
            padding: 0;

            @include btn--simple-text;
            @include type--copy-x-small;

            @include mq('<=sm') {
                margin-top: 8px;
                text-align: left;
            }
        }
    }

    // States
    .cookie-monster--table-row {
        &.cookie-monster--table-row__enabled {
            @include has-focus(true) {
                .cookie-monster--table-cell {
                    background: rgba($c--brand-ink, 0.04);
                }
            }

            .cookie-monster--table-label {
                cursor: pointer;
            }
        }

        &.cookie-monster--table-row__disabled {
            .cookie-monster--table-cell {
                background: rgba($c--monochrome-light-gray, 0.2);
            }
        }
    }
}

