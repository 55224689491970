.snippets-list {
    // Vars
    $sz--padding-y: 24px;
    $sz--padding-x: 24px;

    // Support

    // Module
    & {
        .snippets-list--intro-title {
            @include ff--base-semi-bold;
            @include type--copy-x-small;
            margin-bottom: 12px;
            color: $c--brand-ink;
        }

        .snippets-list--intro-text {
            margin-bottom: 36px;
            max-width: 520px;
            color: $c--monochrome-gray;

            @include type--copy-x-small;
        }

        .snippets-list--entries {
            // Allow room for the last accordion item border
            border-bottom: 2px solid transparent;
        }
    }

    // Facets
    & {
        // Snippets page
        &.snippets-list__snippets {
            padding: $sz--padding-y 0 0;
            margin: 0 $sz--padding-x $sz--padding-y;
        }

        // Snippets chooser overlay
        &.snippets-list__snippets-chooser {
            //
        }
    }

    // States
}
