.hi-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            @include cms--padded-plugin;
        }

        .hi-teaser--title {
            color: $c--brand-ink;
        }

        .hi-teaser--image {
            width: 250px;
        }

        .hi-teaser--list {
            margin-top: 30px;
        }

        .hi-teaser--image-col {
            display: flex;
            align-items: center;
            justify-content: center;

            @include grid--span((ratio: 1/3, mq: '>lg'));
            @include grid--span((ratio: 2/5, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .hi-teaser--text-col {
            display: flex;
            align-items: center;

            @include grid--span((ratio: 1/2, mq: '>lg'));
            @include grid--span((ratio: 3/5, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));

            @include mq('<=sm') {
                margin-top: -12px; // 60px - 48px (it should have 48px distance from top)
            }
        }

        .hi-teaser--list-item ~ .hi-teaser--list-item {
            margin-top: 30px;
        }
    }

    // Facets

    // States
}
