.testimonial-list {
    // Vars

    // Support

    // Module
    & {
        color: $c--brand-ink;

        .testimonial-list--bg {
            @include bg--testimonial();
            @include bg--pattern();
            padding: 60px 0;
        }

        .testimonial-list--more-button {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // Facets

    // States
}
