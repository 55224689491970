.trs-dashboard-placeholder-fade {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--base-speed $trs--base-fn, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;

        /* stylelint-disable declaration-no-important */
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        /* stylelint-enable declaration-no-important */
    }

    &-enter {
        z-index: 1;
    }

    &-leave-to {
        z-index: 0;
    }
}
