.cui-message-editor-proceed-btn {
    // Vars

    // Support

    // Module
    & {
        .cui-message-editor-proceed-btn--btn {
            cursor: pointer;
            margin-top: 24px;
            transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);

            &:disabled {
                cursor: default;
            }
        }
    }


    // Facets
    & {
        &.cui-message-editor-proceed-btn__input {
            .cui-message-editor-proceed-btn--btn {
                margin-top: 0;
            }

            .cui-message-editor-proceed-btn--text {
                display: none;
            }
        }
    }

    // States
}
