/// Field styles
///
@mixin form-field--cui-polar() {
    // Vars
    $sz--radio-icon: 8px;

    // Module
    & {
        .form-field--label-wrap {
            min-width: 70px;
            padding: 11px 16px;
        }

        .form-field--box {
            display: none;
        }

        .form-field--label {
            padding-left: 0;
            text-align: center;
        }
    }

    // States
}
