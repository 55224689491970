.trs-dashboard-sidebar-toggle {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--dashboard-sidebar, transform, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }
}
