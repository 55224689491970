.intro-scroller {
    // Vars
    $c--canvas: $c--monochrome-white;
    $sz--canvas-width: 48px;
    $sz--canvas-height: 48px;

    $c--el: $c--brand-ink;
    $sz--el-width: 3px;
    $sz--el-height: 7px;
    $sz--el-top-offset: 16px;

    $sz--ani-distance: 10px;

    // Support
    @keyframes scroll-intro {
        0% {
            opacity: 0;
            transform: translateX(0.5px);
        }

        20% {
            opacity: 1;
            transform: translateX(0.5px);
        }

        60% {
            opacity: 1;
            transform: translateX(0.5px);
        }

        75% {
            opacity: 0;
            transform: translateX(0.5px) translateY($sz--ani-distance);
        }

        100% {
            opacity: 0;
            transform: translateX(1px) translateY($sz--ani-distance);
        }
    }

    // Module
    & {
        overflow: hidden;

        .intro-scroller--btn {
            position: relative;
            display: block;
            color: $c--canvas;
            cursor: pointer;
        }

        .intro-scroller--ani {
            position: absolute;
            top: $sz--el-top-offset;
            left: ($sz--canvas-width - $sz--el-width) / 2;
            display: block;
            width: $sz--el-width;
            height: $sz--el-height;
            background-color: $c--el;
            border-radius: $sz--el-width;

            animation-name: scroll-intro;
            animation-duration: 2600ms;
            animation-iteration-count: infinite;
        }
    }

    // Facets

    // States
}
