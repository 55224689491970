.date-widget {
    // Vars

    // Support

    // Module
    & {
        .date-widget--popover-target-wrapper {
            pointer-events: none;
            position: relative;
        }

        .date-widget--popover-target {
            position: relative;
            top: -14px;
        }
    }

    // Facets

    // States
}
