/// Field styles
///
@mixin form-field--primary-dashboard-form() {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        .form-field--title-label {
            @include type--copy-small;

            [data-help-text] {
                @include type--copy-xx-small;
                display: block;
                color: $c--monochrome-gray;
            }
        }

        .form-field--title-label-wrap,
        .form-field--input-container-wrap {
            @include grid--span((ratio: 1/2));
            @include grid--span((ratio: 1/1, mq: 'xs'));
        }

        @include mq('>xs') {
            .form-field--title-label-wrap {
                padding-right: 12px;
            }

            .form-field--input-container-wrap {
                padding-left: 12px;

                // Nested form-fields (for example in combinded fields like phone number) don't need this styling:
                .form-field--input-container-wrap {
                    padding-left: 0;
                }
            }
        }

        @include mq('xs') {
            .form-field--title-label-wrap {
                margin-bottom: 8px;
            }
        }
    }

    // Facets
    & {
        // Special styling for nested form-fields (for example combinded fields like phone number)
        & {
            .form-field--input-container-wrap .form-field--input-container-wrap {
                @include grid--span((ratio: 1/1));
            }
        }

        // Special styling for checkboxes
        &.form-field__checkbox {
            .form-field--row {
                justify-content: flex-end;

                @include mq('<=md') {
                    flex-flow: column nowrap;
                }
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary--dashboard-form--base();
        }
    }
}

/// Base
///
@mixin form-field--primary--dashboard-form--base() {
    .form-field--title-label {
        color: $c--monochrome-dark-gray;
    }
}
