.credit-card-payment {
    // Vars

    // Support

    // Module
    & {
        .credit-card-payment--card-wrapper {
            padding: 16px;
            margin-top: 16px;
            margin-bottom: 32px;
            background: $c--monochrome-white;
            border: 1px solid $c--monochrome-light-gray;
        }

        .credit-card-payment--errors {
            padding: 0 0 8px;
        }

        .credit-card-payment--username {
            margin: 16px 0 32px;
            padding: 0;
        }
    }

    // Facets

    // States
}
