.carousel {
    // Vars
    @if not variable-exists(trs--carousel) {
        $trs--carousel-speed: 400ms !global;
        $trs--carousel-fn: ease(in-out-material) !global;
        $trs--carousel: $trs--carousel-speed $trs--carousel-fn !global;
    }

    // Support

    // Module
    & {
        position: relative;
        width: 100%;

        .carousel--root {
            overflow: hidden;
        }

        .carousel--items.carousel--items { // NOTE: Increase selector-weight
            display: block;
        }

        .carousel--wrap {
            position: relative;
            display: flex;
            transition: transform $trs--carousel;
        }

        .carousel--item {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            flex: 0 0 auto;
        }
    }

    // Facets
    & {
        @import 'carousel__gallery'; // Gallery slider
    }

    // States
}
