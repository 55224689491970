/// Field styles
///
@mixin form-field--as-button-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom) {
    // Vars

    // Module
    & {
        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
        }
    }

    // States
}
