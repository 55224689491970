.alert-info--content {
    display: flex;
    font-size: 1.2rem;
    padding: 0.8rem;
    background-color: rgb(227,245,254);
    border-radius: 5px;
    color: rgb(45, 45, 45);
}

.alert-info--text {
    padding: 0 0 0 0.5rem;
}

.alert-info--icon {
    color: rgb(0, 89, 142);
}
