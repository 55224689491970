.dashboard-section {
    // Vars
    $fz--title: $fz--small;
    $lh--title: 1.2;
    $c--shadow: rgba($c--monochrome-dark-gray, 0.2);

    // Support

    // Module
    & {
        .dashboard-section--content {
            box-shadow: 0 0 20px 0 $c--shadow;
        }

        .dashboard-section--header {
            padding: 16px 16px 14px;
            background-color: $c--monochrome-white;
            margin-bottom: 1px;
        }

        .dashboard-section--title {
            @include ff--base-semi-bold;
            font-size: $fz--title;
            line-height: $lh--title;
            color: $c--brand-ink;
        }

        .dashboard-section--text {
            @include type--copy-xx-small;
            margin-top: 4px;
            color: $c--monochrome-dark-gray;
        }

        .dashboard-section--body {
            overflow: hidden;
            background-color: $c--monochrome-white;
        }
    }

    // Facets
    & {
        // Overlay form used for snippets and voucher
        &.dashboard-section__overlay-form {
            .dashboard-section--header {
                padding-left: 24px;
                padding-right: 24px;
                margin-bottom: 0;
                border-bottom: 1px solid $c--brand-ink;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
            }

            .dashboard-section--body {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }

    // States
}
