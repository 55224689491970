@mixin column-separator($num-columns) {
    $bg-image: ();
    $bg-pos: ();
    $c--theme: rgba($c--monochrome-white, 0.3);
    $gradient-definition: linear-gradient(to bottom, $c--theme, $c--theme);

    @for $i from 1 through ($num-columns - 1) {
        $bg-image: append($bg-image, $gradient-definition, 'comma');
        $bg-pos: append($bg-pos, (100% / $num-columns * $i), 'comma');
    }

    background-size: 1px 1px;
    background-repeat: repeat-y;
    background-clip: content-box;
    background-image: $bg-image;
    background-position: $bg-pos;
}
