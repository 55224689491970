.main-nav {
    // Vars

    // Support

    // Module
    & {
        .main-nav--referral-img {
            max-height: 48px;
        }

        .main-nav--referral-program {
            display: flex;
            align-items: center;
        }
    }

    // Facets

    // States
}
