/// Circle shaped button
///
@mixin btn--square($size) {
    min-width: 0;

    &, &::after, &::before {
        border-radius: $sz--border-radius-base;
    }

    @if ($size == 'medium' or (not $size)) {
        padding: 8px;
    }

    @if ($size == 'small') {
        padding: 4px;
    }

    .btn--icon {
        margin: 0;
    }
}
