/// Secondary
///
@mixin btn--pagination-arrow() {
    // Vars

    // Support

    // Module
    & {
        @include btn--all();
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--pagination-arrow-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--pagination-arrow-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--pagination-arrow-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--pagination-arrow-disabled();
        }
    }
}

/// Base
///
@mixin btn--pagination-arrow-base() {
    color: $c--brand-ink;
    background-color: $c--monochrome-white;
}

/// Focus
///
@mixin btn--pagination-arrow-focus() {
    background-color: color-tint($c--brand-background-ink, 90%);
}

/// Active
///
@mixin btn--pagination-arrow-active() {
    background-color: color-tint($c--brand-background-ink, 80%);
}

/// Disabled
///
@mixin btn--pagination-arrow-disabled() {
    color: $c--monochrome-silver;
}
