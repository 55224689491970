.loader-btn {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .loader-btn--content {
            transition: opacity $trs--base;
        }

        .loader-btn--loader {
            @include overlay();
            z-index: 1;
        }
    }

    // Facets
    & {
        // Toggle loader component
        &.loader-btn__is-loading {
            .loader-btn--content {
                opacity: 0;
            }
        }

        // Disabled
        &.loader-btn__is-disabled,
        &:disabled {
            @include btn--apply-states(('focus': default, 'disabled': default)) {
                @include btn--all();
                @include btn--shadowed-base(2px, $c--brand-dark-ink, $sz--border-width-base);
                @include btn--shadowed-active($sz--border-width-base);

                &,
                &::before,
                &::after {
                    cursor: default; // stylelint-disable-line declaration-no-important
                }

                @include btn--shadowed-target-both() {
                    @include btn--secondary-cui-disabled();
                }
            }
        }
    }

    // States
}
