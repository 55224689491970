.gallery {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();

        .gallery--col {
            @include grid--span((ratio: 3/4, mq: '>lg'));
            @include grid--span((ratio: 1, mq: '<=lg'));
        }

        .gallery--empty-message {
            @include ff--base-semi-bold;
            display: block;
            width: 100%;
            padding: 12px 8px;
            text-align: center;
            font-size: $fz--medium;
            line-height: $fz--medium;
            color: $c--monochrome-silver;
        }

        .gallery--widget {
            color: $c--monochrome-white;
        }

        //.gallery--carousel {}

        .gallery--carousel-item {
            @include grid--root();
            @include grid--space((
                row: 'carousel-item-row',
                cols: ('carousel-item-col'),
                gutter: (8px 8px),
            ));
            width: 100%;

            @for $i from 1 through 10 {
                &[data-items-per-slide='#{$i}'] {
                    .gallery--carousel-item-col {
                        @include grid--span((ratio: 1/$i));
                    }
                }
            }
        }

        .gallery--carousel-item-col {
            overflow: hidden;
        }
    }

    // Facets

    // States
}
