.diagnosis-table {
    // Vars
    $sz--padding-y: 24px;
    $sz--padding-x: 24px;
    $c--hover: rgba($c--brand-background-ink, 0.2);

    // Support

    // Module
    & {
        padding: $sz--padding-y 0 0;
        margin: 0;
        width: 100%;

        .diagnosis-table--scroller {
            overflow-x: auto;

            @include mq('<=lg') {
                @include ios-native-scrolling(x);
            }
        }

        .diagnosis-table--el {
            width: 100%;
        }

        .diagnosis-table--tr {
            border-bottom: 1px solid $c--monochrome-light-gray;

            &.diagnosis-table--tr__tbody {
                transition: $trs--base background-color;

                @include has-focus(true) {
                    background-color: $c--hover;
                }
            }
        }

        .diagnosis-table--col {
            &.diagnosis-table--col__td {
                position: relative;
                vertical-align: middle;

                &.diagnosis-table--col--td__diagnosis-name {
                    word-wrap: break-word;
                    word-break: break-all;
                    max-width: 250px;
                }

                .form-field__confidence-level {
                    display: flex;
                    align-items: flex-start;
                    vertical-align: center;
                    padding-left: 20px;

                    .form-field--box {
                        visibility: hidden;
                        display: none;
                    }

                    .form-field--input-container {
                        padding: 0;
                        margin: 3px;
                    }

                    .form-field--label {
                        cursor: pointer;
                        padding: 9px;
                        font-size: $fz--xx-small;
                    }

                    input:checked ~ label {
                        background-color: $c--brand-dark-ink;
                        color: $c--monochrome-white;
                        border: 1px solid $c--brand-dark-ink;
                    }

                    label {
                        border: 1px solid $c--monochrome-light-gray;
                        text-align: center;
                    }
                }
            }
        }

        .diagnosis-table--icon {
            transition: $trs--common-props;
            cursor: pointer;
        }

        .diagnosis-table--icon-actions {
            cursor: pointer;
        }

        .diagnosis-table--display-text-icon {
            display: flex;
        }

        .diagnosis-table--empty-message-wrap {
            text-align: center;
            padding-bottom: 12px;
        }

        .diagnosis-table--empty-message {
            @include type--copy-xx-small();
            color: $c--monochrome-gray;
        }
    }

    // Facets
    & {
        // Empty state
        &.diagnosis-table__is-empty {
            @include mq('<=lg') {
                overflow: hidden;
            }
        }

        // Empty state in facet "absence"
        &.diagnosis-table__snippets.diagnosis-table__is-empty,
        &.diagnosis-table__absence.diagnosis-table__is-empty {
            @include grid--root(());
            @include grid--space((gutter: 24px));

            .diagnosis-table--empty-message-component {
                @include grid--span((ratio: 3/5));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
            }

            .diagnosis-table--children {
                @include grid--span((ratio: 2/5));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-end;
            }
        }

        // With action animations
        &.diagnosis-table__action-animation-move-right,
        &.diagnosis-table__action-animation-scale {
            .diagnosis-table--tr.diagnosis-table--tr__tbody {
                @include has-focus(true) {
                    .diagnosis-table--icon.diagnosis-table--icon__action {
                        opacity: 0.8;
                    }
                }
            }
        }

        // With action animation "move-right"
        &.diagnosis-table__action-animation-move-right {
            .diagnosis-table--tr.diagnosis-table--tr__tbody {
                @include has-focus(true) {
                    .diagnosis-table--icon {
                        &.diagnosis-table--icon__default {
                            color: $c--brand-light-ink;
                            transform: translateX(8px);
                        }
                    }
                }
            }
        }

        // With action animation "scale"
        &.diagnosis-table__action-animation-scale {
            .diagnosis-table--tr.diagnosis-table--tr__tbody {
                @include has-focus(true) {
                    .diagnosis-table--icon {
                        &.diagnosis-table--icon__default {
                            color: $c--brand-light-ink;
                            transform: scale(1.5);
                        }
                    }
                }
            }
        }
    }

    // States
}
