.dashboard-table-empty-message {
    // Vars
    $sz--image: 100px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .dashboard-table-empty-message--image {
            display: block;
            flex: 0 0 $sz--image;
            width: $sz--image;
            height: $sz--image;
        }

        .dashboard-table-empty-message--text {
            @include type--copy-x-small;
            margin-left: 24px;
            color: $c--monochrome-gray;
        }
    }

    // Facets

    // States
}
