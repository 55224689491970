.download-form {
    // Vars

    // Support

    // Module
    & {
        .download-form--content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            width: 100%;
        }

        .download-form--btn-icon.download-form--btn-icon {
            height: 24px;
        }

        .download-form--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            margin: -6px;
        }

        .download-form--action {
            padding: 6px;

            &.download-form--action__download {
                @include at-root(html, '.edge') {
                    display: none;
                }
            }
        }
    }

    // Facets

    // States
}
