.mobile-nav {
    // Vars

    // Support

    // Module
    & {
        .mobile-nav--accessibility-label {
            @include hide-text;
        }

        .mobile-nav--menu {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .mobile-nav--menu-btn {
            padding: 20px;
            color: $c--brand-ink;
            margin-right: -20px;
        }

        .mobile-nav--referral-program {
            display: flex;
            align-items: center;
        }

        .mobile-nav--referral-img {
            max-height: 48px;
        }
    }

    // Facets

    // States
}
