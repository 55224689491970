.dashboard-activity-form {
    // Vars

    // Support

    // Module
    & {
        padding: 16px;

        // NOTE: This violates BEM, but is much easier than the alternatives
        & {
            .form-field--row.form-field--row.form-field--row {
                justify-content: space-between;
            }

            .form-field--input-container.form-field--input-container {
                flex: 0 0 50%;

                @include grid--fix-ie-flex-basis() {
                    width: 50%;
                }
            }

            .form-field--label-wrap.form-field--label-wrap.form-field--label-wrap {
                justify-content: flex-start;
                width: 100%;
            }
        }
    }

    // Facets

    // States
}
