////
/// Button facets and state generation library
////

/// Dependencies and components
///
@import 'btn__helpers';
@import 'btn__states';
@import 'btn__facets';


/// All applicable btn facets, sizes and special types
///
.btn {
    // Vars

    // Support

    // Module

    // Facets
    & {
        // Sizes
        &.btn__medium {
            @include btn--size-medium();
        }

        &.btn__small {
            @include btn--size-small();
        }

        &.btn__x-small {
            @include btn--size-x-small();
        }

        // Facets
        &.btn__primary {
            @include btn--primary();
        }

        &.btn__primary-inv {
            @include btn--primary-inv();
        }

        &.btn__primary-cui {
            @include btn--primary-cui();
        }

        &.btn__secondary {
            @include btn--secondary();
        }

        &.btn__secondary-cui {
            @include btn--secondary-cui();
        }

        &.btn__simple-text {
            @include btn--simple-text();
        }

        &.btn__warning {
            @include btn--warning();
        }

        &.btn__pagination-arrow {
            @include btn--pagination-arrow();
        }

        &.btn__pagination-page {
            @include btn--pagination-page();
        }

        // Special
        &.btn__has-grid {
            @include btn--has-grid();
        }

        &.btn__bubble {
            &.btn__small {
                @include btn--bubble('small');
            }

            &.btn__medium {
                @include btn--bubble('medium');
            }
        }

        &.btn__square {
            &.btn__small {
                @include btn--square('small');
            }

            &.btn__medium {
                @include btn--square('medium');
            }
        }
    }

    // States
}
