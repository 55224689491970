.health-insurance-selection-future {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: calc(436px + 3.2rem);
            max-height: calc(100vh - 3.2rem);
            padding: 1.6rem;
            margin: 0 auto;

            @include mq('<=sm') {
                max-height: calc(100vh - 1.6rem);
            }
        }

        .health-insurance-selection-future--container {
            position: relative;
            display: flex;
            flex-direction: column;
            background: $c--monochrome-white;
            border-radius: $sz--border-radius-base;
            width: 100%;
            height: 100%;
            padding: 0 3.2rem 3.2rem;
            overflow: hidden;

            @include mq('<=sm') {
                padding: 0 1.6rem 1.6rem;
            }
        }

        .health-insurance-selection-future--close-button {
            color: $c--brand-ink;
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;

            @include mq('<=sm') {
                top: 8px;
                transform: scale(0.9);
            }
        }

        .health-insurance-selection-future--title {
            @include type--h2;
            color: $c--brand-ink;
            padding: 1.6rem 3.2rem 1.4rem;
            margin: 0 -3.2rem;
            border-bottom: 1px solid $c--monochrome-light-gray;

            @include at-root(html, '.edge') {
                border-radius: 0;
            }

            @include mq('<=sm') {
                @include type--h3;
            }
        }

        .health-insurance-selection-future--lead {
            @include type--copy-x-small;

            color: $c--brand-ink;
            padding: 1.6rem 0;
        }

        .health-insurance-selection-future--list {
            flex: 1 1;
            overflow-y: scroll;
        }

        .health-insurance-selection-future--list-item {
            display: flex;
            align-items: center;
            padding: 1.6rem;
            background-color: rgba($c--monochrome-light-gray, 0.5);
            transition: background-color 150ms;
            cursor: pointer;
            user-select: none;

            &:hover {
                background-color: darken($c--monochrome-light-gray, 15%);
            }

            & + .health-insurance-selection-future--list-item {
                margin-top: 1.6rem;
            }

            @media (max-width: 374px) {
                align-items: flex-start;
            }
        }

        .health-insurance-selection-future--item-figure {
            margin-right: 12px;
            flex: 0 0 9rem;

            @include mq('<=sm') {
                flex: 0 0 7rem;
            }
        }


        .health-insurance-selection-future--item-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 1;
        }

        .health-insurance-selection-future--item-title {
            @include type--copy-x-small;
            font-weight: 700;
            margin-bottom: 0.4rem;
        }

        .health-insurance-selection-future--item-description {
            font-size: $fz--xx-small;
            line-height: $lh--x-small;
            margin-bottom: 0;
        }

        .health-insurance-selection-future--item-media {
            width: 100%;
        }
    }

    // Facets

    // States
}
