.dashboard-pagination {
    // Vars
    $sz--text-label: 20px;

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: ('col')));
        @include grid--space((gutter: (6px 6px), row: 'row', cols: ('col')));
        padding: 0 0 24px;
        margin: 0 16px;

        .dashboard-pagination--panels {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }

        .dashboard-pagination--panel {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .dashboard-pagination--pages {
            justify-content: center;
        }

        .dashboard-pagination--icon-label,
        .dashboard-pagination--text-label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $sz--text-label;
            height: $sz--text-label;
        }

        .dashboard-pagination--text-label {
            font-size: $fz--small;
            line-height: $lh--small;
            transform: translateY(0.1rem);
        }
    }

    // Facets

    // States
}
