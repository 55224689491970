.dashboard-table {
    // Vars
    $sz--padding-y: 24px;
    $sz--padding-x: 24px;
    $c--hover: rgba($c--brand-background-ink, 0.2);

    // Support

    // Module
    & {
        padding: $sz--padding-y 0 0;
        margin: 0 $sz--padding-x $sz--padding-y;

        .dashboard-table--scroller {
            overflow-x: auto;

            @include mq('<=lg') {
                @include ios-native-scrolling(x);
            }
        }

        .dashboard-table--el {
            width: 100%;
        }

        .dashboard-table--tr {
            border-bottom: 1px solid $c--monochrome-light-gray;

            &.dashboard-table--tr__tbody {
                cursor: pointer;
                transition: $trs--base background-color;

                @include has-focus(true) {
                    background-color: $c--hover;
                }
            }
        }

        .dashboard-table--col {
            &.dashboard-table--col__td {
                position: relative;
                vertical-align: middle;
            }
        }

        .dashboard-table--icon {
            transition: $trs--common-props;
        }

        .dashboard-table--empty-message-wrap {
            text-align: center;
        }

        .dashboard-table--empty-message {
            @include type--copy-xx-small();
            color: $c--monochrome-gray;
        }
    }

    // Facets
    & {
        // Empty state
        &.dashboard-table__is-empty {
            @include mq('<=lg') {
                overflow: hidden;
            }
        }

        // Empty state in facet "absence"
        &.dashboard-table__snippets.dashboard-table__is-empty,
        &.dashboard-table__absence.dashboard-table__is-empty {
            @include grid--root(());
            @include grid--space((gutter: 24px));

            .dashboard-table--empty-message-component {
                @include grid--span((ratio: 3/5));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
            }

            .dashboard-table--children {
                @include grid--span((ratio: 2/5));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-end;
            }
        }

        // With action animations
        &.dashboard-table__action-animation-move-right,
        &.dashboard-table__action-animation-scale {
            .dashboard-table--tr.dashboard-table--tr__tbody {
                @include has-focus(true) {
                    .dashboard-table--icon.dashboard-table--icon__action {
                        opacity: 0.8;
                    }
                }
            }
        }

        // With action animation "move-right"
        &.dashboard-table__action-animation-move-right {
            .dashboard-table--tr.dashboard-table--tr__tbody {
                @include has-focus(true) {
                    .dashboard-table--icon {
                        &.dashboard-table--icon__default {
                            color: $c--brand-light-ink;
                            transform: translateX(8px);
                        }
                    }
                }
            }
        }

        // With action animation "scale"
        &.dashboard-table__action-animation-scale {
            .dashboard-table--tr.dashboard-table--tr__tbody {
                @include has-focus(true) {
                    .dashboard-table--icon {
                        &.dashboard-table--icon__default {
                            color: $c--brand-light-ink;
                            transform: scale(1.5);
                        }
                    }
                }
            }
        }
    }

    // States
}
