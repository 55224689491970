.cui-message-debug-bar {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'buttons', cols: ('button')));
        @include grid--space((gutter: (4px 4px), row: 'buttons', cols: ('button')));
        position: absolute;
        top: 0;
        left: 100%;
        padding: 8px 16px;
        background-color: $c--monochrome-black;

        .cui-message-debug-bar--title {
            color: $c--monochrome-white;
            margin-bottom: 4px;
        }

        .cui-message-debug-bar--data {
            color: $c--monochrome-white;
            font-size: $fz--xx-small;
            margin-bottom: 4px;
        }

        .cui-message-debug-bar--buttons {
            display: flex;
            flex-flow: row nowrap;
        }

        .cui-message-debug-bar--button {
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
        }
    }

    // Facets

    // States
}
