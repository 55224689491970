.tooltip {
    // Vars
    $c--tooltip-bg: $c--monochrome-dark-gray;
    $c--tooltip-text: $c--monochrome-white;
    $c--popover-bg: $c--monochrome-white;
    $c--popover-text: $c--monochrome-black;

    // Support
    @function tooltip--arrow-diag-length($a) {
        @return ceil(($a / 1px) / sqrt(2)) * 1px;
    }

    @function tooltip--rotate-coords($x, $y, $θ) {
        $x2: ($x * cos($θ) - $y * sin($θ));
        $y2: ($x * sin($θ) + $y * cos($θ));
        @return ($x2, $y2);
    }

    @mixin tooltip--modify($transform-modifiers, $offset-modifiers, $arrow-transform-modifiers) {
        $origin-x-values: (left, center, right);
        $origin-y-values: (top, center, bottom);
        $origin-x-transform-modifiers: map-get($transform-modifiers, 'x');
        $origin-y-transform-modifiers: map-get($transform-modifiers, 'y');
        $origin-x-offset-modifiers: map-get($offset-modifiers, 'x');
        $origin-y-offset-modifiers: map-get($offset-modifiers, 'y');
        $origin-x-arrow-transform-modifiers: map-get($arrow-transform-modifiers, 'x');
        $origin-y-arrow-transform-modifiers: map-get($arrow-transform-modifiers, 'y');

        @each $origin-x-value in $origin-x-values {
            $transform-modifier-x: map-get($origin-x-transform-modifiers, $origin-x-value);
            $offset-modifier-x: map-get($origin-x-offset-modifiers, $origin-x-value);
            $arrow-modifier-x: map-get($origin-x-arrow-transform-modifiers, $origin-x-value);

            @each $origin-y-value in $origin-y-values {
                $transform-modifier-y: map-get($origin-y-transform-modifiers, $origin-y-value);
                $offset-modifier-y: map-get($origin-y-offset-modifiers, $origin-y-value);
                $arrow-modifier-y: map-get($origin-y-arrow-transform-modifiers, $origin-y-value);

                /* stylelint-disable max-nesting-depth */
                &[x-origin^='#{$origin-x-value}'][y-origin^='#{$origin-y-value}'] {
                    & {
                        transform: translate($offset-modifier-x, $offset-modifier-y);

                        .tooltip--wrapper {
                            transform: translate($transform-modifier-x, $transform-modifier-y);
                        }
                    }

                    &.tooltip__show-arrow {
                        transform: translate($offset-modifier-x, $offset-modifier-y) translate($arrow-modifier-x, $arrow-modifier-y);

                        .tooltip--wrapper {
                            transform: translate($transform-modifier-x, $transform-modifier-y) translate($arrow-modifier-x, $arrow-modifier-y);
                        }
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    @mixin tooltip--arrow($sz--arrow, $sz--border) {
        $sz--arrow-diag: tooltip--arrow-diag-length($sz--arrow);

        .tooltip--arrow {
            width: $sz--arrow-diag / 2;
            height: $sz--arrow-diag / 2;

            &::before, &::after {
                width: $sz--arrow;
                height: $sz--arrow;
            }

            &::after {
                transform: translate(-50%, -50%) rotateZ(45deg) translate(-$sz--border, -$sz--border);
            }
        }
    }

    // Module
    & {
        pointer-events: none;
        z-index: 10000;
        display: block;
        color: $c--tooltip-bg;

        .tooltip--inner {
            z-index: 3;
        }

        .tooltip--wrapper {
            position: relative;

            &::before,
            &::after {
                @include overlay();
                content: '';
                border-radius: $sz--border-radius-base;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                background-color: $c--tooltip-bg;
            }
        }

        .tooltip--arrow {
            pointer-events: none;
            z-index: 1;
            position: absolute;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                background-color: currentColor;
            }

            &::before {
                transform: translate(-50%, -50%) rotateZ(45deg);
            }
        }
    }

    // Facets
    & {
        // Base style
        &.tooltip__base,
        &.tooltip__inbox-status {
            .tooltip--inner {
                @include type--copy-xx-small;
                position: relative;
                padding: 12px;
                color: $c--tooltip-text;
            }
        }

        &.tooltip__show-arrow {
            &[x-origin^='left'][y-origin^='center'] {
                .tooltip--arrow {
                    top: 50%;
                    left: 100%;
                }
            }

            &[x-origin^='right'][y-origin^='center'] {
                .tooltip--arrow {
                    top: 50%;
                    left: 0;
                    transform: translate(-50%, -50%);
                }
            }

            &[x-origin^='center'][y-origin^='top'] {
                .tooltip--arrow {
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &[x-origin^='center'][y-origin^='bottom'] {
                .tooltip--arrow {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        // TODO: Remove once we have an internal solution for popovers...
        // Currently, this is still necessary in recurring absences
        // Calendar widget
        &.tooltip__calendar-widget {
            $sz--calendar: 280px;

            pointer-events: auto;

            .tooltip--wrapper {
                left: $sz--calendar / 2;
            }

            .tooltip--calendar-outer-wrapper {
                position: relative;
                width: $sz--calendar;
                padding-top: 100%;
            }

            .tooltip--calendar-inner-wrapper {
                @include overlay();
            }

            .tooltip--inner {
                box-shadow:
                    0 3px 6px 0 rgba($c--monochrome-black, 0.16),
                    0 3px 6px 0 rgba($c--monochrome-black, 0.23);
            }

            .tooltip--arrow {
                &, &::before, &::after {
                    display: none;
                }
            }
        }
    }

    // States
    & {
        // Base placement
        &.tooltip__inbox-status {
            $sz--border: 0;
            $sz--arrow: 7px;
            $sz--arrow-diag: tooltip--arrow-diag-length($sz--arrow);
            $sz--tooltip-offset: 0;

            $transform-modifiers: (
                x: (left: -100%, center: -50%, right: 0),
                y: (top: -100%, center: -50%, bottom: 0),
            );
            $offset-modifiers: (
                x: (left: -$sz--tooltip-offset, center: 0, right: $sz--tooltip-offset),
                y: (top: -$sz--tooltip-offset, center: 0, bottom: $sz--tooltip-offset),
            );
            $arrow-modifiers: (
                x: (left: ($sz--arrow-diag / -2), center: 0, right: ($sz--arrow-diag / 2)),
                y: (top: ($sz--arrow-diag / -2), center: 0, bottom: ($sz--arrow-diag / 2)),
            );

            @include tooltip--modify($transform-modifiers, $offset-modifiers, $arrow-modifiers);

            &.tooltip__show-arrow {
                @include tooltip--arrow($sz--arrow, $sz--border);
            }
        }
    }
}
