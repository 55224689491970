.definition-list {
    // Vars
    $fz--text: 1.3rem;
    $lh--text: 1.2;
    $sz--padding-x: 16px;
    $sz--padding-y: 12px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row wrap;

        .definition-list--item {
            @include grid--span((ratio: 1));
            padding: $sz--padding-y $sz--padding-x;
            border-bottom: 1px solid $c--monochrome-light-gray;
            overflow: visible;
        }

        .definition-list--definition {
            @include type--copy-xx-small();
            color: $c--brand-ink;
        }

        .definition-list--term {
            display: block;
            font-size: $fz--text;
            line-height: $lh--text;
            overflow: visible;
        }

        .definition-list--image-wrap {
            margin-top: 6px;
            overflow: hidden;
        }

        .definition-list--component-wrap {
            padding-top: 12px;
            overflow: visible;
        }

        .definition-list--image-row {
            overflow: hidden;
        }

        .definition-list--component-row.definition-list--component-row.definition-list--component-row {
            flex-flow: row nowrap;
            overflow: hidden;
        }

        .definition-list--image-col {
            @include grid--span((ratio: 1));
            overflow: hidden;
        }

        .definition-list--component-col {
            overflow: hidden;
        }

        .definition-list--image-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100%;
            background-color: $c--monochrome-light-gray;
        }

        .definition-list--image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
        }
    }

    // Facets
    & {
        // Grid list
        &.definition-list__grid {
            .definition-list--item {
                @include mq('>sm') {
                    @include grid--span((ratio: 1/2));

                    &:nth-child(2n + 0) {
                        border-left: 1px solid $c--monochrome-light-gray;
                    }
                }
            }
        }

        // No borders between items
        &.definition-list__simple {
            padding: $sz--padding-y 0;

            .definition-list--item {
                border: none;
            }
        }

        // In expandable list
        &.definition-list__in-expandable-list {
            padding-bottom: 0;
        }

        &.definition-list__summary {
            .definition-list--item {
                padding: 0;
                border: none;

                ~ .definition-list--item {
                    margin-top: 32px;
                }
            }

            .definition-list--image-col {
                @include grid--span((ratio: 1/3));
            }

            .definition-list--definition {
                @include type--copy-small;
            }

            .definition-list--term {
                @include type--copy;
            }
        }

        &.definition-list__pdf {
            @media print {
                .definition-list--item {
                    padding-left: 0;
                    padding-right: 0;
                }

                .definition-list--definition {
                    font-size: 8pt;
                    line-height: 1.2;

                    &::after {
                        content: '';
                    }
                }
            }
        }

        &.definition-list__illustrated {
            .definition-list--item {
                border: none;
                padding: 3mm 0;
            }

            .definition-list--image-part {
                position: relative;
                display: inline-block;
                width: 38mm;
                height: 38mm;
                vertical-align: top;

                background-color: $c--monochrome-light-gray;
            }

            .definition-list--image {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 38mm;
                max-height: 38mm;
                transform: translate(-50%, -50%);
            }

            .definition-list--description-part {
                display: inline-block;
                width: 86mm;
                margin-left: 8mm;
                vertical-align: top;
            }
        }

        &.definition-list__treatment {
            .definition-list--item {
                padding: 0;
                border: none;

                ~ .definition-list--item {
                    margin-top: 32px;
                }
            }
        }

        &.definition-list__doctor-detail {
            .definition-list--item {
                padding: 8px 0;
                border: none;
            }

            .definition-list--definition {
                color: $c--monochrome-gray;
                font-size: $lh--medium;
                line-height: $lh--medium;
            }

            .definition-list--term {
                @include type--copy-x-small();
            }
        }

        // A section within the dashboard
        &.definition-list__dashboard {
            .definition-list--item {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                padding: 20px 24px;
            }

            .definition-list--definition,
            .definition-list--term {
                @include grid--span((ratio: 1/2));
                @include grid--span((ratio: 1/1, mq: 'xs'));
                @include type--copy-small;
            }

            @include mq('>xs') {
                .definition-list--definition {
                    padding-right: 12px;
                }

                .definition-list--term {
                    padding-left: 12px;
                }
            }

            .definition-list--definition {
                color: $c--monochrome-dark-gray;

                &::after {
                    display: none;
                }
            }

            .definition-list--term {
                color: $c--monochrome-gray;
            }
        }

        // Within an overlay form
        &.definition-list__overlay-form {
            .definition-list--item {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }

    // States
}
