.language-nav {
    // Vars
    $c--shadow: #333333;
    $sz--item-border-height: 3px;
    $sz--lang-nav-width: 80px;

    // Support
    @mixin language-nav--link() {
        @include ff--base-semi-bold;
        @include type--copy-small;
        transition: color $trs--base;
        cursor: pointer;

        @include has-focus(true) {
            color: $c--brand-ink;
        }
    }

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: (20px 12px), row: 'items', cols: ('item')));
        margin-left: 40px;

        .language-nav--toggle {
            @include language-nav--link();
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $sz--lang-nav-width;
            height: $sz--main-nav-height;
            padding: 4px 0;
            text-transform: uppercase;
            transition: color $trs--base;

            .language-nav--text {
                padding: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -4px;
                width: 1px;
                height: 22px;
                transform: translateY(-50%);
                background-color: $c--brand-ink;
            }
        }

        .language-nav--wrap {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 44px;
        }

        .language-nav--icon {
            position: relative;
            transition: transform $trs--base;
        }

        .language-nav--dropdown {
            z-index: 1;
            position: absolute;
            top: 100%;
            padding: 26px 0;
            width: $sz--lang-nav-width;
            border-top: 1px solid $c--monochrome-light-gray;
            background-color: $c--monochrome-white;
        }

        .language-nav--item {
            @include grid--span((ratio: 1/1));
            text-align: center;
        }

        .language-nav--link {
            @include language-nav--link();
            display: block;
            width: 100%;
        }

        .language-nav--text {
            position: relative;
            display: inline-block;
            padding: 0 0 2px;
            text-transform: uppercase;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: $sz--item-border-height;
                background-color: $c--brand-ink;
                opacity: 0;

                transform: translateY(-$sz--item-border-height);
                transition: transition--props($trs--base, opacity, transform);
            }
        }
    }

    // Facets

    // States
    & {
        // Link is active
        & {
            .language-nav--link.language-nav--link__is-active {
                @include has-focus(default) {
                    color: $c--brand-ink;
                    cursor: default;

                    .language-nav--text::after {
                        opacity: 1;
                    }
                }
            }
        }

        // In `main-nav` and `dashboard-header
        &.language-nav__in-main-nav,
        &.language-nav__in-dashboard-nav {
            .language-nav--toggle {
                z-index: 2;
                background-color: $c--monochrome-white;
            }

            .language-nav--dropdown {
                box-shadow: 0 0 10px 0 rgba($c--shadow, 0.2);
            }
        }


        // In `main-nav`
        &.language-nav__in-main-nav {
            margin-left: 32px;
            color: $c--monochrome-gray;

            .language-nav--root {
                position: absolute;
            }

            .language-nav--dropdown {
                z-index: 1;
                position: absolute;
                top: 100%;
                padding: 26px 0;
                border-top: 1px solid $c--monochrome-light-gray;
                background-color: $c--monochrome-white;
            }

            .language-nav--item {
                @include grid--span((ratio: 1/1));
            }

            .language-nav--link {
                margin-left: 1px;
            }
        }

        // In `dashboard-header`
        &.language-nav__in-dashboard-nav {
            margin-left: 0;
            color: $c--monochrome-gray;

            .language-nav--toggle::before {
                display: none;
            }
        }

        // In `mobile-nav-list`
        & {
            &.language-nav__is-mobile-nav {
                margin-left: 0;

                .language-nav--dropdown {
                    position: relative;
                    width: 100%;
                    padding-top: 40px;
                }

                .language-nav--toggle {
                    display: none;
                }

                .language-nav--items {
                    flex-flow: row nowrap;
                    justify-content: center;
                }

                .language-nav--item {
                    flex: 1 1 auto;
                }

                .language-nav--link {
                    @include type--copy();
                }
            }
        }

        // `language-nav` is open
        &.language-nav__is-active {
            .language-nav--toggle {
                @include has-focus(default) {
                    color: $c--brand-ink;
                }
            }

            .language-nav--icon {
                transform: rotateX(0.5turn);
            }
        }

        // Link is active
        & {
            & {
                .language-nav--link.language-nav--link__is-active {
                    @include has-focus(default) {
                        color: $c--brand-ink;
                        cursor: default;
                    }
                }
            }
        }
    }
}
