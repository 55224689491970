.footer-nav {
    // Vars

    // Support

    // Module
    & {
        @include bg--footer();
        z-index: z-index('footer-nav');
        position: relative;
        padding: 80px 0;
        color: $c--monochrome-white;

        @include mq('<=md') {
            padding: 0;
        }

        .footer-nav--col {
            @include grid--span((ratio: 1/1));
        }
    }

    // Facets

    // States
}
