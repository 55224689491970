.trs-cui-edit-toggle {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--cui-message-edit, opacity, height);
    }

    &-enter-active {
        z-index: 1;
        position: relative;
    }

    &-leave, &-leave-to, &-leave-active {
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-height: 100%;

        @at-root .cui-message.cui-message__is-injecting & {
            top: inherit;
            bottom: 0;
        }
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
