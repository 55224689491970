.notification-message {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: ('icon', 'text')));
        @include grid--space((gutter: 8px, row: 'row', cols: ('icon', 'text')));

        .notification-message--body {
            padding: 14px 0;
        }

        .notification-message--row {
            flex-flow: row nowrap;
        }

        .notification-message--icon {
            flex: 0 0 auto;
            align-self: center;
        }

        .notification-message--text {
            @include type--copy-x-small;
            flex: 0 1 100%;

            a {
                color: currentColor;
                text-decoration: none;
                transition: transition-props($trs--base, color, border-color);

                @include has-focus(false) {
                    text-decoration: none;
                    border-bottom: 1px solid $c--monochrome-white;
                }

                @include has-focus(true) {
                    color: $c--monochrome-light-gray;
                    border-color: $c--monochrome-light-gray;
                }
            }
        }
    }

    // Facets
    & {
        &.notification-message__success {
            .notification-message--bg {
                background-color: $c--signal-success;
            }

            .notification-message--body {
                color: $c--monochrome-white;
            }
        }

        &.notification-message__warning {
            .notification-message--bg {
                background-color: $c--signal-warning;
            }
        }

        &.notification-message__error {
            .notification-message--bg {
                background-color: $c--signal-error;
            }

            .notification-message--body {
                color: $c--monochrome-white;
            }
        }
    }

    // States
}
