/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--semi-bold: 600;

/// The project's font families
///
$ff--base: 'Montserrat';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Verdana, Geneva, sans-serif;
// stylelint-enable value-keyword-case
