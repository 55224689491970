.modal {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .modal--close-wrap {
            position: absolute;
            top: 0;
        }

        .modal--close {
            z-index: 3;
            position: fixed;
        }

        .modal--close-button {
            cursor: pointer;
            display: block;
            padding: 10px;
        }
    }

    // Facets
    & {
        // Full width modal overlay
        &.modal__base {
            width: 100%;

            .modal--close {
                right: 0;
                transform: translateX(-100%);
            }
        }

        // Full width
        &.modal__cui-overview,
        &.modal__confirmation,
        &.modal__toast {
            width: 100%;
        }

        // Hide close button
        &.modal__tooltip,
        &.modal__voucher-form,
        &.modal__snippets-form,
        &.modal__cui-overview,
        &.modal__confirmation,
        &.modal__dashboard-sidebar,
        &.modal__toast,
        &.modal__mobile-nav,
        &.modal__image-viewer,
        &.modal__body-regions {
            .modal--close-wrap {
                display: none;
            }
        }

        // Full size modals
        &.modal__image-viewer,
        &.modal__mobile-nav {
            width: 100%;

            &,
            .modal--body {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                width: 100%;
            }
        }

        // Dashboard sidebar
        &.modal__dashboard-sidebar {
            .modal--body {
                height: 100%;
                padding-top: $sz--dashboard-header-height;

                @include mq('<=lg') {
                    @include ios-native-scrolling;
                    pointer-events: auto;
                }
            }
        }

        // Body regions
        &.modal__body-regions {
            pointer-events: auto;
            width: 100%;

            &,
            .modal--body {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                width: 100%;
            }
        }

        // Tooltip
        &.modal__tooltip {
            position: static;
        }

        &.modal__snippets-chooser {
            padding-top: $sz--dashboard-header-height;

            &,
            .modal--body {
                display: flex;
                flex-flow: column nowrap;
                flex: 0 1 100%;
                width: 100%;
            }

            .modal--body {
                pointer-events: auto;
            }

            .modal--close-wrap {
                top: $sz--dashboard-header-height + 32px;
                left: 0;
            }

            .modal--close {
                right: 32px;
            }

            .modal--close-button {
                color: $c--brand-ink;
                transition: color $trs--base;

                @include has-focus(true) {
                    color: $c--brand-dark-ink;
                }
            }
        }
    }

    // States
}
