.dropdown-links {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        margin-bottom: 18px;
    }

    // Facets

    // States
}
