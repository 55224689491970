/// Field styles
///
@mixin form-field--primary-all() {
    // Vars
    $fz--form-field: $fz--small;
    $lh--form-field: 1.38;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;

        .form-field--input-container {
            position: relative;
            display: flex;
            font-size: $fz--form-field;
            line-height: $lh--form-field;
        }

        .form-field--title-label {
            @include ff--base-regular;
            display: block;
            margin-bottom: 6px;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-all-base();
        }

        // Required
        @include form-field--apply-states(('required': true)) {
            @include form-field--primary-all-required();
        }
    }
}

/// Base
///
@mixin form-field--primary-all-base() {
    .form-field--title-label {
        color: $c--label-text;
    }
}

/// Required
///
@mixin form-field--primary-all-required() {
    //
}
