.form-submit-base {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        width: 100%;

        .form-submit-base--wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }

        .form-submit-base--additional-content {
            order: -1;
            margin-right: 16px;
        }
    }

    // Facets

    // States
}
