.partner-image {
    // Vars
    $sz--image-big: 70%;
    $sz--image-small: 80%;

    // Support

    // Module
    & {
        width: 100%;

        .partner-image--outer {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 9 / 16 * 100%;
        }

        .partner-image--inner {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $sz--image-big;
            height: $sz--image-big;
            transform: translate(-50%, -50%);

            @include mq('<=sm') {
                width: $sz--image-small;
                height: $sz--image-small;
            }
        }

        .partner-image--element {
            display: inline-block;
            max-height: 100%;
            max-width: 100%;
        }
    }

    // Facets

    // States
}
