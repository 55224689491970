/// Field styles
///
@mixin form-field--primary-bool($fz--label, $lh--label, $sz--box, $sz--box-border) {
    // Vars
    $sz--label-gutter: 8px;

    $sz--list-gutter-x: 20px;
    $sz--list-gutter-y: 8px;

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container')));
        font-size: $fz--label;
        line-height: 0;

        .form-field--input {
            @include hide-visually;
        }

        .form-field--input-container {
            z-index: 0;
            display: flex;
            flex: 0 0 auto;
        }

        .form-field--title-label {
            cursor: auto;
        }

        .form-field--label-wrap {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            cursor: pointer;
            max-height: $sz--box;
            transition: $trs--common-props;
        }

        .form-field--label {
            flex: 1 0 auto;
            padding: 0 0 0 $sz--label-gutter;

            // Center label on the basis of the flex value `align-items: baseline`
            transform: translateY((($fz--label - $sz--box) / 2) - $sz--box-border - 0.5px);
        }

        .form-field--box {
            @include is-selectable(false);

            position: relative;
            display: block;
            flex: 0 0 auto;
            width: $sz--box;
            height: $sz--box;
            transition: $trs--common-props;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: $trs--common-props;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                border-width: $sz--box-border;
                border-style: solid;
            }
        }

        .form-field--box-icon {
            pointer-events: none;
            z-index: 3;
            opacity: 0;
            transition: $trs--common-props;
        }
    }

    // Facets
    & {
        &.form-field__vertical {
            .form-field--row {
                flex-flow: column nowrap;
            }
        }

        &.form-field__multiline {
            .form-field--label-wrap {
                max-height: none;
            }

            .form-field--label {
                flex: 0 1 auto;
                line-height: $lh--label;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            .form-field--title-label {
                color: $c--monochrome-gray;
            }

            .form-field--input {
                // Base
                @include form-field--bool-apply-states(()) {
                    @include form-field--primary-bool-base();
                }

                // Hover
                @include form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    @include form-field--primary-bool-hover();
                }

                // Focus
                @include form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    @include form-field--primary-bool-focus();
                }

                // Checked
                @include form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include form-field--primary-bool-checked();
                }

                // Checked and focus
                @include form-field--bool-apply-states(('disabled': false, 'checked': true, 'hover': default, 'focus': true)) {
                    @include form-field--primary-bool-focus();
                }

                // Disabled
                @include form-field--bool-apply-states(('disabled': true)) {
                    @include form-field--primary-bool-disabled();
                }

                // Disabled and checked
                @include form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include form-field--primary-bool-disabled-checked();
                }
            }
        }

        // Error
        @include form-field--apply-states(('error': true)) {
            .form-field--title-label {
                color: $c--signal-error;
            }

            .form-field--input {
                // Base
                @include form-field--bool-apply-states(()) {
                    @include form-field--primary-bool-error();
                }

                // Hover
                @include form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    //
                }

                // Focus
                @include form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    //
                }

                // Checked
                @include form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include form-field--primary-bool-error();
                }

                // Checked and focus
                @include form-field--bool-apply-states(('checked': true, 'hover': default, 'focus': true)) {
                    @include form-field--primary-bool-focus();
                }

                // Disabled
                @include form-field--bool-apply-states(('disabled': true)) {
                    @include form-field--primary-bool-disabled();
                }

                // Disabled and checked
                @include form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include form-field--primary-bool-disabled-checked();
                }
            }
        }
    }
}

/// Base
///
@mixin form-field--primary-bool-base() {
    .form-field--box::before {
        opacity: 0;
    }

    .form-field--box {
        background-color: $c--monochrome-white;
    }

    .form-field--box::after {
        border-color: $c--monochrome-silver;
    }

    .form-field--box-icon {
        color: $c--monochrome-silver;
    }
}

/// Hover
///
@mixin form-field--primary-bool-hover() {
    .form-field--box-icon {
        opacity: 0.25;
    }
}

/// Focus
///
@mixin form-field--primary-bool-focus() {
    .form-field--box::before {
        opacity: 1;
    }

    .form-field--box::after {
        border-color: $c--monochrome-gray;
    }
}

/// Checked
///
@mixin form-field--primary-bool-checked() {
    .form-field--box::after {
        border-color: $c--brand-ink;
    }

    .form-field--box-icon {
        opacity: 1;
        color: $c--brand-ink;
    }
}

/// Error
///
@mixin form-field--primary-bool-error() {
    .form-field--box::after {
        border-color: $c--signal-error;
    }
}

/// Disabled
///
@mixin form-field--primary-bool-disabled() {
    cursor: default;

    .form-field--label {
        cursor: auto;
    }

    .form-field--box {
        background-color: $c--monochrome-light-gray;
    }

    .form-field--box::after {
        border-color: $c--monochrome-silver;
    }

    .form-field--box-icon {
        color: $c--monochrome-silver;
    }
}

/// Disabled and checked
///
@mixin form-field--primary-bool-disabled-checked() {
    .form-field--box-icon {
        color: $c--monochrome-silver;
    }

    .form-field--box::after {
        border-color: $c--monochrome-silver;
    }
}
