.widget-action.widget-action {
    // Vars

    // Support

    // Module
    & {
        cursor: pointer;
        display: block;
        opacity: 0;
        transition: transition-props($trs--base, opacity, color);
        color: $c--brand-ink;

        @include has-focus(true) {
            opacity: 1;
            color: $c--brand-light-ink;
        }

        .widget-action--bg {
            @include size(48px);
            pointer-events: none;
            display: block;
            background-color: $c--monochrome-white;
            border-radius: $sz--border-radius-base;
            box-shadow: 0 2px 8px 0 rgba(#333333, 0.2);
            transform: rotateZ(1turn); // NOTE: In Safari 12, the shadow was not shown without hardware-acceleration
        }

        .widget-action--icon {
            width: 100%;
            height: 100%;
            color: currentColor;
        }
    }

    // Facets

    // States
}
