.snippet-entry {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'buttons', cols: ('button')));
        @include grid--space((gutter: (4px 4px), row: 'buttons', cols: ('button')));

        .snippet-entry--buttons-wrap {
            margin-bottom: 24px;
        }

        .snippet-entry--buttons {
            flex-flow: column nowrap;
        }

        .snippet-entry--button {
            display: inline-flex;
        }

        .snippet-entry--item + .snippet-entry--item {
            margin-top: 16px;
        }

        .snippet-entry--item-description,
        .snippet-entry--item-title {
            line-height: $lh--small;

            @include type--copy-x-small;
        }

        .snippet-entry--item-description {
            color: $c--monochrome-gray;
        }
    }

    // Facets

    // States
}
