.form-submit-recaptcha {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        width: 100%;

        .form-submit-recaptcha--wrap {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            width: 100%;
        }

        .form-submit-recaptcha--vue-recaptcha {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .form-submit-recaptcha--additional-content {
            order: -1;
            margin-right: 16px;
        }

        .form-submit-recaptcha--notice-text {
            display: block;
            margin-top: 8px;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
        }
    }

    // Facets

    // States
}
