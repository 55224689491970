.form-submit-with-payment {
    // Vars

    // Support

    // Module
    & {
        &.form-submit-with-payment {
            padding: 32px;
            margin-top: 8px;
            margin-bottom: 16px;
            background: $c--monochrome-pale-white;
        }

        .form-submit-with-payment--select {
            margin-bottom: 24px;
        }

        .form-submit-with-payment--title {
            margin-bottom: 8px;
        }

        .form-submit-with-payment--description {
            margin-bottom: 16px;

            @include type--copy-small;
        }

        .form-submit-with-payment--wrap {
            display: flex;
            justify-content: flex-end;
        }
    }

    // Facets

    // States
}
