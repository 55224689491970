.wide-placeholder {
    // Vars

    // Support

    // Module
    & {
        overflow: visible;
    }

    // Facets

    // States
}
