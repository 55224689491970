.page.page__type-login {
    // Vars

    // Support

    // Module
    & {
        justify-content: center;
        align-items: stretch;
        padding: 40px 0;

        .page--main {
            flex: 0 1 auto;
        }

        &::before {
            @include bg--title();
        }

        &::after {
            @include bg--pattern();
        }
    }

    // Facets

    // States
}
