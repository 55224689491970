.faq-section {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();

        + .faq-section {
            margin-top: ((60px * 2) - 40px) * -1;

            @include mq('<=sm') {
                margin-top: ((40px * 2) - 40px) * -1;
            }
        }

        .faq-section--title {
            color: $c--brand-ink;
            margin-bottom: 20px;
        }

        .faq-section--description {
            margin-top: -16px;
            margin-bottom: 20px;
        }

        .faq-section--item {
            border-bottom: 2px solid $c--monochrome-light-gray;

            &:first-child {
                border-top: 2px solid $c--monochrome-light-gray;
            }
        }
    }

    // Facets

    // States
}

