.trs-cui-inject {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-move {
        transition: transform $trs--cui-message-inject-speed $trs--cui-message-inject-fn $trs--cui-message-inject-delay;
    }
}
