.login-placeholder {
    // Vars

    // Support

    // Module
    & {
        .login-placeholder--container.login-placeholder--container {
            overflow: visible;
        }

        .login-placeholder--row {
            justify-content: center;
        }

        .login-placeholder--col {
            @include grid--span((ratio: 1));
        }
    }

    // Facets

    // States
}
