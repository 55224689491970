/// Field styles
///
@mixin form-field--primary-file($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    // Vars

    // Support

    // Module
    & {
        .form-field--input-container {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: 0;
                border-bottom: 1px solid transparent;
                transition: border-color $trs--base;
            }
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            display: inline-block;
            width: 100%;
            font-size: 1.4rem;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
            border: none;
            border-radius: 0;
            line-height: 1.38;
            transition: $trs--common-props;
            overflow: hidden;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-file-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-file-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-file-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary-file-error();
        }
    }
}

/// Base
///
@mixin form-field--primary-file-base() {
    .form-field--title-label {
        color: $c--monochrome-gray;
    }

    .form-field--input {
        color: $c--label-text;
        background-color: transparent;
        border-color: $c--monochrome-silver;

        @include placeholder() {
            color: $c--monochrome-silver;
        }

        &:-webkit-autofill {
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-box-shadow: 0 0 0 100px rgba($c--monochrome-white, 1) inset;
        }
    }
}

/// Focus
///
@mixin form-field--primary-file-focus() {
    .form-field--title-label {
        color: $c--signal-info;
    }

    .form-field--input-container::after {
        border-color: $c--brand-ink;
    }

    .form-field--input {
        border-color: $c--brand-ink;
    }
}

/// Disabled
///
@mixin form-field--primary-file-disabled() {
    .form-field--title-label {
        @include is-selectable(false);
        cursor: default;
    }

    .form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-silver;
        background-color: transparent;
        border-color: $c--monochrome-silver;
        cursor: default;

        @include placeholder() {
            color: transparent;
        }
    }
}

/// Error
///
@mixin form-field--primary-file-error() {
    .form-field--input-container::after {
        border-color: $c--signal-error;
    }

    .form-field--input-container::after {
        border-color: $c--signal-error;
    }

    .form-field--input {
        border-color: $c--signal-error;
    }

    .form-field--title-label {
        color: $c--signal-error;
    }
}
