.new-feature-info {
    // Module
    & {
        .new-feature-info-box {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 25px;
            background: #ffb81c;
            display: flex;
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 2px;
            padding-bottom: 2px;

            .new-feature-info-text {
                font-size: $fz--small;
                padding-right: 8px;
                //this is used, so that the click event from the button works correctly on GTM (even if you click on the SVG inside of the button)
                pointer-events: none;
            }

            .new-feature-info-icon {
                margin-top: 3px;
                //this is used, so that the click event from the button works correctly on GTM (even if you click on the SVG inside of the button)
                pointer-events: none;
            }
        }
    }
}
