.body-regions-preview {
    // Vars

    // Support

    // Module
    & {
        @include grid--root(());
        @include grid--space((gutter: 8px));

        .body-regions-preview--wrap {
            position: relative;
            display: flex;
            justify-content: center;
            overflow: hidden;
        }

        .body-regions-preview--row {
            flex-flow: row nowrap;
            justify-content: center;
            align-items: stretch;
            height: 100%;

            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }

        .body-regions-preview--actions {
            position: absolute;
            top: -25px; // This is visually aligned with a `definition-list` title in the dashboard
            right: 0;
            flex-flow: column nowrap;
            overflow: visible;
        }

        .body-regions-preview--col {
            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }
    }

    // Facets
    & {
        // In dashboard
        &.body-regions-preview__in-dashboard {
            .body-regions-preview--col {
                @include grid--span((ratio: 1/5, mq: 'md'));
                @include grid--span((ratio: 1/4, mq: 'sm'));
                @include grid--span((ratio: 1/2, mq: 'xs'));
            }
        }

        // In summary
        &.body-regions-preview__in-summary {
            .body-regions-preview--col {
                @include grid--span((ratio: 1/3, mq: '>md'));
                @include grid--span((ratio: 1/3, mq: 'md'));
                @include grid--span((ratio: 1/3, mq: 'sm'));
                @include grid--span((ratio: 1/2, mq: 'xs'));
            }
        }
    }

    // States
    & {
        .body-regions-preview--wrap {
            @include has-focus(true) {
                .body-regions-preview--action {
                    opacity: 1;
                }
            }
        }
    }
}
