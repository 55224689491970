.table-col.table-col__title {
    // Vars

    // Support

    // Module
    & {
        padding: 4px 16px;
        white-space: nowrap;

        .table-col--label {
            display: block;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
            color: $c--brand-ink;
        }
    }

    // Facets

    // States
}
