.benefits {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin('.benefits--bg');
        z-index: 0;
        position: relative;
        overflow: hidden;

        .benefits--bg {
            @include bg--title();
        }

        .benefits--intro {
            color: $c--monochrome-white;
        }

        .benefits--separator {
            display: block;
            margin: 12px 0 36px;
        }

        .benefits--col {
            @include grid--span((ratio: 1/4, mq: '>lg'));
            @include grid--span((ratio: 1/2, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .benefits--item {
            position: relative;
            width: 100%;
            padding-top: 100%;
            border-radius: 50%;
            overflow: hidden;

            @include mq(('>sm', '<=sm')) {
                width: $sz--illustration;
                padding-top: $sz--illustration;
                margin: 0 auto;
            }
        }

        .benefits--video,
        .benefits--illustration {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
        }

        .benefits--illustration {
            z-index: 1;

            // NOTE: This may change when disabling the video for page weight or performance reasons
            display: none;
        }

        .benefits--video {
            z-index: 2;
            border-radius: 50%;
            overflow: hidden;
        }

        .benefits--label {
            @include type--copy-large;
            display: block;
            margin-top: 24px;
            text-align: center;
            color: $c--monochrome-white;
        }

        .benefits--more {
            margin-top: 60px;
            text-align: center;

            @include mq('<=sm') {
                margin-top: 48px;
            }
        }
    }

    // Facets

    // States
}
