.doctor-detail {
    // Vars
    $sz--map-ratio: (100% * 1 / 3);

    // Support

    // Module
    & {
        .doctor-detail--content {
            overflow: hidden;
            padding: 36px 36px 60px;
            background-color: $c--monochrome-white;

            @include mq('<=sm') {
                padding: 16px;
            }
        }

        .doctor-detail--row {
            overflow: hidden;
        }

        .doctor-detail--aside {
            @include grid--span((ratio: 4/12, mq: '>sm'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .doctor-detail--main {
            @include grid--span((ratio: 8/12, mq: '>sm'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .doctor-detail--availability {
            @include type--copy-x-small();
            margin-top: 4px;
            color: $c--monochrome-gray;
        }

        .doctor-detail--image-wrap {
            position: relative;
            width: 100%;
            padding-top: 100%;

            @include mq('<=sm') {
                margin: 8px 0;
            }
        }

        .doctor-detail--image {
            @include overlay();
        }

        .doctor-detail--action {
            margin-top: 24px;
        }

        .doctor-detail--btn {
            display: block;
            width: 100%;
            text-align: center;
        }

        .doctor-detail--map-container {
            overflow: hidden;
        }

        .doctor-detail--mobile-action {
            z-index: z-index('overlay');
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 16px;
        }
    }

    // Facets

    // States
}
