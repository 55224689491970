/// Field styles
///
@mixin form-field--primary-search() {
    // Vars

    // Module
    & {
        .form-field--input {
            @include ff--base-regular;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-search-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-search-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-search-disabled();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--primary-search-empty();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--primary-search-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--primary-search-base() {
    .form-field--action.form-field--action__toggle {
        color: $c--monochrome-light-gray;

        @include has-focus(true) {
            color: $c--brand-dark-ink;
        }
    }

    .form-field--action.form-field--action__loader {
        width: 32px;
        height: 32px;
        padding: 0;
    }
}

/// Focus
///
@mixin form-field--primary-search-focus() {
    .form-field--action.form-field--action__toggle {
        color: $c--brand-light-ink;
    }
}

/// Disabled
///
@mixin form-field--primary-search-disabled() {
    .form-field--action.form-field--action__toggle {
        display: none;
    }
}

/// Empty
///
@mixin form-field--primary-search-empty() {
    .form-field--input {
        color: $c--monochrome-silver;
    }
}

/// Empty and disabled
///
@mixin form-field--primary-search-empty-disabled() {
    .form-field--input {
        color: transparent;
    }
}
