.expandable-list {
    // Vars

    // Support

    // Module
    & {
        .expandable-list--expander {
            width: 100%;
        }

        .expandable-list--expander-btn {
            @include ff--base-semi-bold;
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            padding: 20px 16px;
            cursor: pointer;
            transition: color $trs--base;

            @include has-focus(false) {
                color: $c--brand-ink;
            }

            @include has-focus(true) {
                color: $c--brand-dark-ink;
            }
        }

        .expandable-list--expander-label {
            @include type--copy-xx-small();
            flex: 0 1 100%;
            text-align: left;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }

        .expandable-list--expander-icon {
            flex: 0 0 auto;
            transition: transform $trs--base;
        }
    }

    // Facets

    // States
    & {
        &.expandable-list__is-active {
            .expandable-list--expander-icon {
                transform: rotateX(0.5turn);
            }
        }
    }
}
