.dashboard-header {
    // Vars
    $sz--image-height: 48px;

    // Support

    // Module
    & {
        .dashboard-header--logo {
            padding-right: 18px;

            @include mq('<=sm') {
                padding-right: 0;
            }
        }

        .dashboard-header--languages {
            padding-right: 18px;

            @include mq('<=sm') {
                padding-right: 0;
            }
        }

        .dashboard-header--menu {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding-left: 36px;
            overflow: visible;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 0;
                height: $sz--image-height;
                border-left: 1px solid $c--brand-ink;
                transform: translate(-50%, -50%);
            }

            @include mq('<=sm') {
                padding-left: 18px;
            }
        }

        .dashboard-header--doctor-image {
            margin-right: 12px;

            @include mq('<=sm') {
                margin-right: 0;
            }

            &.u-router-link-exact-active {
                @include has-focus(default) {
                    cursor: default;
                }
            }
        }

        .dashboard-header--doctor-image-element {
            display: block;
            width: $sz--image-height;
            height: $sz--image-height;
        }

        .dashboard-header--doctor-name {
            @include type--copy-small;
            @include text-ellipsis;
            font-weight: $fw--semi-bold;
            white-space: nowrap;
            color: $c--monochrome-gray;
            transition: color $trs--base;

            @include mq('<=sm') {
                display: none;
            }

            @include has-focus(true) {
                color: $c--brand-ink;
            }

            &.u-router-link-exact-active {
                @include has-focus(default) {
                    cursor: default;
                    color: $c--monochrome-gray;
                }
            }
        }

        .dashboard-header--mobile-nav-btn {
            $sz--btn-padding: 8px;

            padding: $sz--btn-padding;
            margin-left: 36px;
            color: $c--brand-ink;

            @include mq('<=sm') {
                padding-left: 0;
                padding-right: 0;
                margin-left: 18px;
            }
        }
    }

    // Facets

    // States
}
