.mobile-nav-btn {
    // Vars
    $sz--bar-height: 3px;
    $sz--icon-width: 32px;
    $sz--icon-height: 26px;

    // Support
    @function mobile-nav-btn--rotate-coords($x, $y, $θ) {
        $x2: ($x * cos($θ) - $y * sin($θ));
        $y2: ($x * sin($θ) + $y * cos($θ));
        @return ($x2, $y2);
    }

    // Module
    & {
        cursor: pointer;

        .mobile-nav-btn--root {
            position: relative;
            display: block;
            width: $sz--icon-width;
            height: $sz--icon-height;
        }

        .mobile-nav-btn--bar {
            position: absolute;
            left: 0;
            top: 50%;

            display: block;
            width: 100%;
            height: $sz--bar-height;

            background-color: currentColor;
            opacity: 1;

            transition: transition-props($trs--base-speed ease-out, transform, opacity, background-color);

            &:nth-child(1) {
                transform: translateY($sz--icon-height * -0.5);
            }

            &:nth-child(2) {
                transform: translateY($sz--bar-height * -0.5);
            }

            &:nth-child(3) {
                transform: translateY(($sz--icon-height * 0.5)  - $sz--bar-height);
            }
        }
    }

    // Facets

    // States
    & {
        // Disabled
        &.mobile-nav-btn__is-disabled {
            cursor: default;

            .mobile-nav-btn--bar {
                background-color: $c--monochrome-light-gray;
            }
        }

        // Is active
        &.mobile-nav-btn__is-active {
            .mobile-nav-btn--bar {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
