.dashboard-placeholder {
    // Vars

    // Support

    // Module
    & {
        .dashboard-placeholder--row {
            position: relative;
            overflow: hidden;
        }

        .dashboard-placeholder--col {
            @include grid--span((ratio: 1));
        }
    }

    // Facets

    // States
}
