.accordion {
    // Vars
    @if not variable-exists(trs--accordion) {
        $trs--accordion-speed: 300ms !global;
        $trs--accordion-fn: ease !global;
        $trs--accordion: $trs--accordion-speed $trs--accordion-fn !global;
    }

    // Support

    // Module
    & {
        .accordion--head {
            display: block;
            width: 100%;
            text-align: left;
            cursor: pointer;
        }

        .accordion--body {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transform: rotateZ(1turn);
        }
    }

    // Facets
    & {
        &.accordion__base {
            &.accordion__is-animating {
                & {
                    .accordion--body {
                        @include is-visible(false, $trs--accordion-speed, opacity, max-height);
                    }
                }

                &.accordion__is-active {
                    .accordion--body {
                        @include is-visible(true, $trs--accordion-speed, opacity, max-height);
                    }
                }
            }
        }

        &.accordion__cui {
            &.accordion__is-animating {
                & {
                    .accordion--body {
                        @include is-visible(false, $trs--cui-accordion-speed, opacity, max-height);
                    }
                }

                &.accordion__is-active {
                    .accordion--body {
                        @include is-visible(true, $trs--cui-accordion-speed, opacity, max-height);
                    }
                }
            }
        }
    }

    // States
    & {
        & {
            .accordion--body {
                visibility: hidden;
                opacity: 0;
            }
        }

        &.accordion__is-active {
            .accordion--body {
                visibility: inherit;
                opacity: 1;
            }
        }
    }
}
