.nav-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('link')));
        @include grid--space((gutter: 36px, row: 'items', cols: ('link')));
        flex: 1 0 auto;
        color: $c--monochrome-gray;

        .nav-list--items {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
        }

        .nav-list--link {
            @include ff--base-semi-bold;
            @include type--copy-small;
            display: block;
            height: $sz--main-nav-height;
            transition: color $trs--base;
            cursor: pointer;

            @include has-focus(true) {
                color: $c--brand-ink;
            }
        }

        .nav-list--link-text-wrapper {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            height: 100%;
            white-space: nowrap;
        }
    }

    // Facets

    // States
}
