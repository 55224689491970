.form-field__polar-link {
    & {
        .form-field--row {
            margin-bottom: 10px;
        }

        .form-field-button {
            @include btn--base();
            background-color: #00629b;
            padding: 10px 20px 8px;
            font-size: $fz--medium;
            color: #ffffff;
            border-bottom-color: #004e7c;
        }


        .form-field-button:hover {
            border-bottom-color: #004e7c;
            background-color: #3381af;
            color: #ffffff;
        }
    }
}
