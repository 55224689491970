.trs-simple-move {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-move {
        transition: transform $trs--base;
    }
}
