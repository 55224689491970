.main-placeholder {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        &.main-placeholder__in-landingpage {
            padding: 30px 0;

            @include mq('<=md') {
                padding: 20px 0;
            }
        }

        &.main-placeholder__with-vertical-padding {
            padding: 60px 0;

            @include mq('<=md') {
                padding: 30px 0;
            }
        }
    }
}
