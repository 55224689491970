.general-error {
    // Vars

    // Support

    // Module
    & {
        @include type--copy-xx-small();
        display: inline-block;
        color: $c--signal-error;
    }

    // Facets

    // States
}
