.form {
    // Vars
    $sz--form-fieldset-gutter: (48px 16px) !global;
    $sz--form-field-gutter: (32px 16px) !global;

    $grid--fieldset-items: (row: ('fieldset-row'), cols: ('fieldset'));
    $grid--field-items: (row: ('field-row'), cols: ('field'));

    // Support

    // Module
    & {
        // Fieldset spacing
        & {
            @include grid--root($grid--fieldset-items);
            @include grid--space(map-merge($grid--fieldset-items, (
                gutter: $sz--form-fieldset-gutter,
            )));

            .form--fieldset {
                @include grid--span((ratio: 1/1));
            }
        }

        // Field spacing
        & {
            @include grid--root($grid--field-items);
            @include grid--space(map-merge($grid--field-items, (
                gutter: $sz--form-field-gutter,
            )));

            .form--field-row {
                ~ .form--field-row {
                    padding-top: (nth($sz--form-field-gutter, 1) / 2);
                }
            }

            .form--field {
                @include grid--span((ratio: 1/1));
                align-self: flex-start;
            }

            .form--general-errors {
                margin-top: 8px;
            }
        }
    }

    // Facets
    & {
        // Login
        &.form__login {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            width: 100%;

            @include at-root(html, '.ie11') {
                display: block;
            }

            .form--fieldset {
                display: flex;
                flex-flow: column nowrap;
            }

            .form--field-row {
                flex-flow: column nowrap;
                flex: 1 1 auto;
            }

            .form--field {
                flex: 1 0 auto;
                width: 100%;
                overflow: hidden;

                &.form--field__submit {
                    flex: 1 0 auto;
                }
            }
        }

        // Personal data
        &.form__personal-data {
            .form--field.form--field__submit {
                margin-top: 48px;
            }
        }
    }

    // States
}
