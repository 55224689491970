.skel {
    // vars
    //

    // base
    & {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-height: 100%;
        overflow: hidden;

        //.skel--header {}

        .skel--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
        }

        //.skel--footer {}
        //.skel--root {}
    }
}
