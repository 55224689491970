.trs-flip-password-toggle-icon {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--flip-password-toggle-speed $trs--flip-password-toggle-fn, opacity, transform);
    }

    &-enter, &-leave-to {
        opacity: 0;
        transform: rotateX(0.25turn);
    }
}
