.cui-message-display-content-body-regions {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;

        @include at-root(html, '.ie11') {
            width: $sz--body-regions-display-width;
        }

        @include at-root(html, '.ie10') {
            width: $sz--body-regions-display-width;
        }

        .cui-message-display-content-body-regions--side {
            height: $sz--body-regions-display-height;

            &:nth-child(1) {
                padding-right: 4px;
            }

            &:nth-child(2) {
                padding-left: 4px;
            }
        }
    }

    // Facets

    // States
}
