/// Creates a font-face definition
/// @param {string} $path - An url to the font files without any extension
/// @example scss - Basic usage
///    @include _font--create('#{$web-static}/fonts/#{$ff--base}/HelveticaNeue') {
///        font-family: 'HelveticaNeue';
///        font-weight: 700;
///        font-style: normal;
///    }
///
@mixin _font--create($path: null) {
    @font-face {
        @content;

        @if ($path) {
            src:
                url('#{$path}.woff2') format('woff2'),
                url('#{$path}.woff') format('woff'),
                url('#{$path}.ttf') format('truetype');
        }
    }
}
