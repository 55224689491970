.page {
    // Vars

    // Support

    // Module
    & {
        padding-bottom: 40px;
    }

    // Facets
    & {
        &.page__has-bg,
        &.page__has-footer {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            flex: 1 0 auto;

            .page--main {
                flex: 1 0 auto;
            }
        }

        &.page__has-footer {
            padding-bottom: 0;
        }

        &.page__has-menu {
            @include mq('>#{$bp--mobile}') {
                padding-top: $sz--main-nav-height;
            }

            @include mq('<=#{$bp--mobile}') {
                padding-top: $sz--mobile-nav-height;
            }
        }

        &.page__has-bg {
            position: relative;

            &::before,
            &::after {
                @include overlay(fixed);
                content: '';
                pointer-events: none;
            }

            &::before {
                @include bg--cui();
            }

            &::after {
                @include bg--pattern();
            }

            .page--main {
                z-index: 1;
                position: relative;
            }
        }

        &.page__bg-dashboard {
            &::before {
                @include bg--title();
            }

            &::after {
                @include bg--pattern();
            }
        }
    }

    // States
}
