.dashboard-title {
    // Vars
    $sz--title-height-big: 90px;

    // Support

    // Module
    & {
        @include mq('<=sm') {
            padding: 24px 0;
        }

        .dashboard-title--row {
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .dashboard-title--title,
        .dashboard-title--date {
            @include ff--base-semi-bold;
            @include type--copy-small;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: $sz--title-height-big;
            color: $c--monochrome-white;
            white-space: nowrap;

            @include mq('<=sm') {
                height: auto;
            }
        }
    }

    // Facets

    // States
}
