.phone-widget {
    // Vars
    $grid--field-items: (row: ('row'), cols: ('col'));

    // Support

    // Module
    & {
        @include grid--root($grid--field-items);
        @include grid--space(map-merge($grid--field-items, (gutter: $sz--form-field-gutter)));

        .phone-widget--label {
            z-index: 1;
            position: relative;
        }

        .phone-widget--content {
            margin-top: -22px; // NOTE: Corresponds with floating label upper padding
            overflow: hidden;
        }

        .phone-widget--row {
            display: flex;
            flex-flow: row nowrap;
            flex: 1 1 auto;
        }

        .phone-widget--select.phone-widget--select {
            @include grid--span((ratio: 5/24));
            @include grid--span((ratio: 1/4, mq: 'xs'));
            align-self: flex-end;
        }

        .phone-widget--input.phone-widget--input {
            @include grid--span((ratio: 19/24));
            @include grid--span((ratio: 3/4, mq: 'xs'));
        }
    }

    // Facets

    // States
}
