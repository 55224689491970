.form-fieldset {
    // Vars

    // Support

    // Module
    & {
        .form-fieldset--col {
            @include grid--span((ratio: 1/1));
        }

        .form-fieldset--label {
            //
        }
    }

    // Facets

    // States
}
