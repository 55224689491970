.dashboard-form {
    // Vars
    $sz--spacing: 24px;

    // Support

    // Module
    & {
        .dashboard-form--col {
            @include grid--span((ratio: 1/1));
        }

        .dashboard-form--footer {
            display: flex;
            flex-flow: row nowrap;
            padding: 20px 24px;
        }

        .dashboard-form--errors {
            flex: 1 0 0;
            padding-right: 16px;
        }

        .dashboard-form--submit {
            flex: 0 1 auto;
        }
    }

    // Facets

    // States
}
