.page.page__type-report-detail {
    // Vars

    // Support

    // Module
    & {
        .page--patient {
            @include grid--span((ratio: 4/12, mq: '>md'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
            overflow: hidden;
        }

        .page--form {
            @include grid--span((ratio: 8/12, mq: '>md'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
            overflow: hidden;
        }
    }

    // Facets

    // States
}
