@mixin cms--padded-plugin($selector: '') {
    &.grid__cms #{$selector} {
        padding: 60px 0;

        @include mq('<=md') {
            padding: 40px 0;
        }
    }

    &.grid__landingpage #{$selector} {
        padding: 30px 0;

        @include mq('<=md') {
            padding: 20px 0;
        }
    }
}
