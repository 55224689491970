.date-range-widget {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'sections', cols: ('section')));
        @include grid--space((gutter: (48px 48px), row: 'sections', cols: ('section')));

        .date-range-widget--wrapper {
            padding: 24px;
        }

        .date-range-widget--section {
            overflow: hidden;

            &.date-range-widget--section__calendar {
                @include grid--span((ratio: 1/3));
                @include grid--span((ratio: 1/2, mq: ('>xs' '<=md')));
                @include grid--span((ratio: 1/1, mq: 'xs'));
            }

            &.date-range-widget--section__fields {
                @include grid--span((ratio: 14/24));
                @include grid--span((ratio: 1/2, mq: ('>xs' '<=md')));
                @include grid--span((ratio: 1/1, mq: 'xs'));
            }

            &.date-range-widget--section__field {
                @include grid--span((ratio: 1/2));
                @include grid--span((ratio: 1/1, mq: ('>xs' '<=md')));
                @include grid--span((ratio: 1/1, mq: 'xs'));
            }
        }

        .date-range-widget--calendar-outer-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100%;
        }

        .date-range-widget--calendar-inner-wrapper {
            @include overlay();
        }
    }

    // Facets

    // States
}
