/// Form field facet
///
@mixin form-field--primary() {
    // Vars
    $sz--input-padding-top: 1px;
    $sz--input-padding-x: 0;
    $sz--input-padding-bottom: 6px;

    $sz--input-border-width: 1px;

    $fz--bool-label: 1.6rem / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 4px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    $sz--actions-icon-offset: 0;
    $sz--actions-icon-margin: 0;
    $sz--actions-icon-width: 16px;

    $sz--floating-label-offset: 8px;
    $sz--floating-label-padding-top: 15px + $sz--floating-label-offset;
    $sz--floating-label-padding-bottom: 15px - $sz--floating-label-offset;

    // Support

    // Fields
    & {
        @include form-field--primary-all();

        &.form-field__hidden {
            @include form-field--primary-hidden();
        }

        &.form-field__file {
            @include form-field--primary-file(
                $sz--padding-top: $sz--input-padding-top * 4,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--border-width: $sz--input-border-width
            );
        }

        &.form-field__input {
            @include form-field--primary-input(
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--border-width: $sz--input-border-width
            );

            &.form-field__textarea {
                @include form-field--primary-textarea();
            }

            &.form-field__select {
                @include form-field--primary-select();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom
                );
            }

            &.form-field__search {
                @include form-field--primary-search();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom
                );

                &.form-field__floating-label {
                    &.form-field__password {
                        @include form-field--primary-has-actions(
                            $sz--icon-margin: $sz--actions-icon-margin,
                            $sz--icon-offset: $sz--actions-icon-offset,
                            $sz--icon-width: $sz--actions-icon-width,
                            $sz--padding-top: $sz--floating-label-padding-top,
                            $sz--padding-x: $sz--input-padding-x,
                            $sz--padding-bottom: $sz--floating-label-padding-bottom
                        );
                    }
                }
            }

            &.form-field__password {
                @include form-field--primary-password();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom
                );

                &.form-field__floating-label {
                    &.form-field__password {
                        @include form-field--primary-has-actions(
                            $sz--icon-margin: $sz--actions-icon-margin,
                            $sz--icon-offset: $sz--actions-icon-offset,
                            $sz--icon-width: $sz--actions-icon-width,
                            $sz--padding-top: $sz--floating-label-padding-top,
                            $sz--padding-x: $sz--input-padding-x,
                            $sz--padding-bottom: $sz--floating-label-padding-bottom
                        );
                    }
                }
            }

            &.form-field__secondary {
                @include form-field--secondary-search();
            }
        }

        &.form-field__bool {
            @include form-field--primary-bool(
                $fz--label: $fz--bool-label,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.form-field__radio {
                @include form-field--primary-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.form-field__checkbox {
                @include form-field--primary-checkbox();
            }
        }
    }

    // Addons
    & {
        &.form-field__dashboard-form {
            @include form-field--primary-dashboard-form();
        }

        &.form-field__floating-label {
            @include form-field--primary--floating-label(
                $sz--padding-top: $sz--floating-label-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--floating-label-padding-bottom
            );
        }

        &.form-field__has-actions {
            @include form-field--primary-has-actions(
                $sz--icon-margin: $sz--actions-icon-margin,
                $sz--icon-offset: $sz--actions-icon-offset,
                $sz--icon-width: $sz--actions-icon-width,
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom
            );
        }

        &.form-field__image-widget-text-area {
            @include form-field--primary-image-widget-text-area();
        }

        &.form-field__report-form {
            @include form-field--primary-report-form();
        }
    }
}
