.dashboard-sidebar {
    // Vars
    $fz--notification: 1.1rem;
    $sz--notification-min-size: 17px;
    $c--shadow: $c--monochrome-dark-gray;

    // Support

    // Module
    & {
        height: 100%;
        padding: 24px 0;
        color: $c--brand-bright-ink;
        background-color: $c--brand-black-ink;

        .dashboard-sidebar--link {
            @include type--copy-small;
            font-weight: $fw--semi-bold;
            display: block;
            padding: 12px 24px;
            transition: $trs--common-props;

            &:not(.u-router-link-active) {
                @include has-focus(true) {
                    background-color: $c--brand-dark-ink;
                }
            }

            &.u-router-link-active {
                color: $c--monochrome-white;
                cursor: default;
            }
        }

        .dashboard-sidebar--notification-bubble {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: $fz--notification;
            line-height: 1;
            padding: 0 4px;
            margin-left: 6px;
            color: $c--monochrome-white;
            background-color: $c--signal-error;
            min-width: $sz--notification-min-size;
            min-height: $sz--notification-min-size;
            border-radius: $sz--notification-min-size / 2;
            text-indent: -0.25px;
            transform: translateY(-1px) rotateZ(1turn);
        }

        .dashboard-sidebar--notification-bubble-text {
            position: relative;
            display: block;
            transform: rotateZ(1turn);
        }
    }

    // Facets
    & {
        &.dashboard-sidebar__overlay {
            pointer-events: auto;
            width: $sz--dashboard-sidebar-width;
            height: auto;
            min-height: 100%;
            box-shadow: 0 3px 10px 0 rgba($c--shadow, 0.2);
        }
    }

    // States
}
