/// Form field facet
///
@mixin form-field--cui() {
    // Vars
    $sz--input-padding-x: 14px;
    $sz--input-padding-y: 14px;

    $sz--input-border-width: 2px;

    $fz--bool-label: 1.6rem / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 2px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    $sz--actions-icon-offset: 16px;
    $sz--actions-icon-margin: 8px;
    $sz--actions-icon-width: 36px;

    // Support

    // Fields
    & {
        @include form-field--cui-all();

        //&.form-field__hidden {
        //    @include form-field--cui-hidden();
        //}

        &.form-field__input {
            @include form-field--cui-input(
                $sz--padding-top: $sz--input-padding-y,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-y,
                $sz--border-width: $sz--input-border-width
            );

            &.form-field__textarea {
                @include form-field--primary-textarea();
            }
        }

        &.form-field__bool {
            @include form-field--cui-bool(
                $fz--label: $fz--bool-label,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.form-field__radio {
                @include form-field--cui-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.form-field__checkbox {
                @include form-field--cui-checkbox();
            }

            &.form-field__file {
                @include form-field--cui-file(
                    $sz--box-border: $sz--bool-box-border
                );
            }

            &.form-field__polar {
                @include form-field--cui-polar();
            }
        }

        &.form-field__cui-body-regions {
            @include form-field--cui-body-regions();
        }
    }

    // Addons
    & {
        &.form-field__has-actions {
            @include form-field--primary-has-actions(
                $sz--icon-margin: $sz--actions-icon-margin,
                $sz--icon-offset: $sz--actions-icon-offset,
                $sz--icon-width: $sz--actions-icon-width,
                $sz--padding-top: $sz--input-padding-y,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-y
            );
        }
    }
}
