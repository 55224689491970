.trs-cui-message-display-emerge {
    // Vars
    $trs--initial-delay: 400ms;
    $trs--duration-pt-1: 400ms;
    $trs--duration-pt-2: 200ms;
    $trs--fn: ease(out-material);
    $trs--full-duration: $trs--initial-delay + $trs--duration-pt-1 + $trs--duration-pt-2;

    // Support

    // Module

    // Facets

    // States
    &-enter-active {
        transition: opacity $trs--full-duration linear;

        .trs-message-emerge--bubble {
            transition:
                opacity 200ms $trs--fn,
                width $trs--duration-pt-1 $trs--fn $trs--initial-delay,
                height $trs--duration-pt-1 $trs--fn $trs--initial-delay;
        }

        .trs-message-emerge--content {
            $delay: $trs--initial-delay + $trs--duration-pt-1;
            transition: opacity $trs--duration-pt-2 $trs--fn $delay;
        }
    }

    &-enter {
        opacity: 0.999;

        .trs-message-emerge--bubble.trs-message-emerge--bubble.trs-message-emerge--bubble { // NOTE: Increase selctor weight
            opacity: 0;
            width: $sz--bubble-initial-size;
            height: $sz--bubble-initial-size;
        }

        .trs-message-emerge--content {
            opacity: 0;
        }
    }

    &-enter-to {
        .trs-message-emerge--bubble.trs-message-emerge--bubble { // NOTE: Increase selctor weight
            opacity: 1;
        }

        // NOTE: MS Edge needs to specifically have the opacity of the child element changed
        .trs-message-emerge--content {
            opacity: 1;
        }
    }
}
