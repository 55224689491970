.cui-manager {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'messages', cols: ('message')));
        @include grid--space((gutter: (12px 12px), row: 'messages', cols: ('message')));
        display: flex;
        flex: 1 0 auto;
        overflow: visible;
        padding-bottom: 52px;

        @include mq('>#{$bp--mobile}') {
            padding-top: $sz--main-nav-height + $sz--cui-nav-height;
        }

        @include mq('<=#{$bp--mobile}') {
            padding-top: $sz--mobile-nav-height + $sz--cui-nav-height;
        }

        .cui-manager--container.cui-manager--container {
            display: flex;
            flex: 1 0 auto;
            margin: 0 auto;
            overflow: visible;
        }

        .cui-manager--row {
            justify-content: center;
            flex: 1 0 auto;
        }

        .cui-manager--col {
            @include grid--span((ratio: 6/12, mq: '>sm'));
            @include grid--span((ratio: 1, mq: '<=sm'));
            display: flex;
            overflow: visible;
        }

        .cui-manager--messages {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
        }

        .cui-manager--message {
            display: flex;
            flex-flow: column nowrap;
        }
    }

    // Facets

    // States
}
