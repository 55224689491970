.image-viewer {
    // Vars
    $sz--action: 48px;
    $sz--padding-big: 24px;
    $sz--padding-small: 16px;

    // Support

    // Module
    & {
        width: 100%;

        &, .image-viewer--element {
            @include mq('>sm') {
                padding: $sz--padding-big;
            }

            @include mq('<=sm') {
                padding: $sz--padding-small;
            }
        }

        .image-viewer--wrapper {
            pointer-events: auto;
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0;
            background-color: $c--monochrome-white;
        }

        .image-viewer--element {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            max-height: 100%;
            max-width: 100%;
            transform: translate(-50%, -50%);
        }

        .image-viewer--action {
            cursor: pointer;
            position: absolute;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $sz--action;
            height: $sz--action;
            border-radius: 3px;
            color: $c--brand-ink;
            background-color: $c--monochrome-white;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-light-ink;
            }

            &.image-viewer--action__close {
                z-index: 1;
                top: $sz--padding-big;
                right: $sz--padding-big;

                @include mq('>sm') {
                    top: $sz--padding-big;
                    right: $sz--padding-big;
                }

                @include mq('<=sm') {
                    top: 0;
                    right: 0;
                }
            }

            &.image-viewer--action__prev,
            &.image-viewer--action__next {
                top: 50%;
                transform: translateY(-50%);
            }

            &.image-viewer--action__prev {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                @include mq('>sm') {
                    left: $sz--padding-big;
                }

                @include mq('<=sm') {
                    left: 0;
                }
            }

            &.image-viewer--action__next {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                @include mq('>sm') {
                    right: $sz--padding-big;
                }

                @include mq('<=sm') {
                    right: 0;
                }
            }
        }
    }

    // Facets

    // States
    & {
        .image-viewer--action {
            &.image-viewer--action__is-disabled {
                @include has-focus(default) {
                    cursor: default;
                    color: $c--monochrome-light-gray;
                }
            }
        }
    }
}
