/// Global type definitions
///
$fz--xx-small: 1.2rem;
$fz--x-small: 1.5rem;
$fz--small: 1.6rem;
$fz--medium: 1.8rem;
$fz--large: 2.1rem;
$fz--x-large: 2.8rem;

$lh--xx-small: 1.25;
$lh--x-small: 1.5;
$lh--small: 1.67;
$lh--medium: 1.56;
$lh--large: 1.43;
$lh--x-large: 1.36;

/// Headline definitions
///
@mixin type--h1 {
    @include ff--base-semi-bold;
    font-size: 3.6rem;
    line-height: 1.22;

    @include mq('<=sm') {
        font-size: 2.8rem;
        line-height: 1.29;
    }

    @media print {
        font-size: 22pt;
        line-height: 1.2;
    }
}

@mixin type--h2 {
    @include ff--base-semi-bold;
    color: $c--brand-ink;
    font-size: 2.1rem;
    line-height: 1.43;

    @media print {
        font-size: 12pt;
        line-height: 1.2;
    }
}

@mixin type--h3 {
    @include ff--base-semi-bold;
    font-size: 1.8rem;
    line-height: 1.56;
}

@mixin type--h4 {
    @include ff--base-semi-bold;
    font-size: 1.8rem;
}

@mixin type--h5 {
    @include ff--base-semi-bold;
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--h6 {
    @include ff--base-semi-bold;
    font-size: $fz--medium;
    line-height: $lh--medium;
}


@mixin type--copy-xx-small {
    font-size: $fz--xx-small;
    line-height: $lh--xx-small;
}

@mixin type--copy-x-small {
    font-size: $fz--x-small;
    line-height: $lh--x-small;
}

@mixin type--copy-small {
    font-size: $fz--small;
    line-height: $lh--small;
}

@mixin type--copy {
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--copy-large {
    font-size: $fz--large;
    line-height: $lh--large;
}

@mixin type--copy-x-large {
    font-size: $fz--x-large;
    line-height: $lh--x-large;
}

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--base-regular;
    @include type--copy;
    color: $c--label-text;
}

/// Headlines
///
.skel {
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    .u-reset.u-reset__copy {
        @include type--copy;
    }

    .u-reset.u-reset__copy-small {
        @include type--copy-small;
    }

    .u-reset.u-reset__copy-x-small {
        @include type--copy-xx-small;
    }

    .u-reset.u-reset__copy-large {
        @include type--copy-large;
    }

    .u-reset.u-reset__copy-x-large {
        @include type--copy-x-large;
    }

    /// Tags with visual implication
    ///
    b, strong {
        font-weight: $fw--semi-bold;
    }

    i, em {
        font-style: italic;
    }

    sub {
        font-size: $fz--xx-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--xx-small;
        vertical-align: super;
    }

    /// Simple link
    ///
    a, .u-link {
        color: currentColor;
        text-decoration: none;

        &:not(.u-reset):not(.btn) {
            transition: color $trs--base;

            @include has-focus(false) {
                text-decoration: none;
                border-bottom: 1px solid $c--brand-ink;
            }

            @include has-focus(true) {
                color: $c--label-link;
            }
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        display: block;
        border: 0;
        height: 1px;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
        background-color: currentColor;
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}
