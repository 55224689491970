.logo {
    // Vars
    $sz--logo-x: 124px;
    $sz--logo-y: 48px;

    $sz--logo-img-x: 104px;
    $sz--logo-img-y: 40px;

    // Support

    // Module
    & {
        display: flex;

        .logo--link {
            display: block;
            transition: color $trs--base;

            @include has-focus(false) {
                color: $c--brand-ink;
            }

            @include has-focus(true) {
                color: $c--brand-dark-ink;
            }

            &.u-router-link-exact-active {
                @include has-focus(default) {
                    cursor: default;
                    color: $c--brand-ink;
                }
            }
        }

        .logo--img {
            width: $sz--logo-x;
            height: $sz--logo-y;
        }
    }

    // Facets
    & {
        &.logo__bright {
            .logo--link {
                @include has-focus(false) {
                    color: $c--monochrome-white;
                }

                @include has-focus(true) {
                    color: $c--brand-background-ink;
                }

                &.u-router-link-exact-active {
                    @include has-focus(default) {
                        color: $c--monochrome-white;
                    }
                }
            }
        }

        &.logo__mobile {
            .logo--img {
                width: $sz--logo-img-x;
                height: $sz--logo-img-y;
            }
        }

        &.logo__dashboard-header {
            @include mq('<=sm') {
                .logo--img {
                    width: $sz--logo-img-x;
                    height: $sz--logo-img-y;
                }
            }
        }
    }

    // States
}
