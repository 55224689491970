.body-regions-preview-modal {
    // Vars
    $sz--action: 48px;
    $sz--padding-big: 24px;
    $sz--padding-small: 16px;

    // Support

    // Module
    & {
        @include grid--root(());
        @include grid--space((gutter: 24px));
        width: 100%;

        @include mq('>sm') {
            padding: $sz--padding-big;
        }

        @include mq('<=sm') {
            padding: $sz--padding-small;
        }

        .body-regions-preview-modal--wrapper {
            pointer-events: auto;
            position: relative;
            width: 100%;
            height: 100%;
            padding: 24px;
            text-align: center;
            font-size: 0;
            background-color: $c--monochrome-white;
        }

        .body-regions-preview-modal--row {
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .body-regions-preview-modal--col {
            height: 100%;

            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }

        .body-regions-preview-modal--action {
            cursor: pointer;
            position: absolute;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $sz--action;
            height: $sz--action;
            border-radius: 3px;
            color: $c--brand-ink;
            background-color: $c--monochrome-white;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-bright-ink;
            }

            &.body-regions-preview-modal--action__close {
                z-index: 1;
                top: $sz--padding-big;
                right: $sz--padding-big;

                @include mq('>sm') {
                    top: $sz--padding-big;
                    right: $sz--padding-big;
                }

                @include mq('<=sm') {
                    //top: 0;
                    //right: 0;
                }
            }
        }
    }

    // Facets

    // States
}
