.snippets-chooser-modal {
    // Vars
    $sz--bottom-bar-height-large: 100px;
    $sz--bottom-bar-height-small: 84px;

    // Support

    // Module
    & {
        @include grid--root((row: 'lists', cols: ('list', 'intro')));
        @include grid--space((gutter: (48px, 24px), row: 'lists', cols: ('list', 'intro')));

        position: relative;
        flex: 0 1 100%;
        width: 100%;
        padding: 48px 0 $sz--bottom-bar-height-large;
        background-color: $c--monochrome-white;

        @include mq('<=sm') {
            padding-bottom: $sz--bottom-bar-height-small;
        }

        .snippets-chooser-modal--stage {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .snippets-chooser-modal--close {
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
        }

        .snippets-chooser-modal--close-button {
            display: block;
            padding: 16px;
            color: $c--brand-ink;
            cursor: pointer;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-light-ink;
            }
        }

        .snippets-chooser-modal--close-icon {
            pointer-events: none;
        }

        .snippets-chooser-modal--title {
            @include ff--base-semi-bold;
            @include type--copy-x-small;
            margin-bottom: 12px;
            color: $c--brand-ink;
        }

        .snippets-chooser-modal--text {
            max-width: 520px;
            color: $c--monochrome-gray;

            @include type--copy-x-small;
        }

        .snippets-chooser-modal--intro,
        .snippets-chooser-modal--list {
            @include grid--span((ratio: 1/1));
        }
    }

    // Facets

    // States
}
