.health-insurance-overlay-private {
    & {
        & {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        .health-insurance-overlay-private--info {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            justify-content: center;
            padding-left: 13px;
            padding-right: 13px;
            align-items: center;
            text-align: center;
        }

        .health-insurance-overlay-private--insurance-name {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            padding-bottom: 10px;
            color: #00629b;
        }

        .health-insurance-overlay-private--insurance-description {
            font-size: 16px;
            font-style: normal;
        }

        .health-insurance-overlay-private--item-media {
            padding-bottom: 10px;
        }

        @media (max-width: 400px) {
            .health-insurance-overlay-private--insurance-description,
            .health-insurance-overlay-private--insurance-name {
                font-size: 14px;
            }
        }
    }
}
