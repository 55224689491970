.cui-message-display-content {
    // Vars
    $sz--image-max: 200px;

    // Support

    // Module
    & {
        padding: ($sz--bubble-padding-y + 1px) $sz--bubble-padding-x ($sz--bubble-padding-y - 1px);

        .cui-message-display-content--text {
            @include type--copy-small;
            word-break: break-word;
        }

        .cui-message-display-content--title,
        .cui-message-display-content--text {
            display: block;
            text-align: left;
            min-width: 24px;
        }

        .cui-message-display-content--image {
            @include size($sz--image-max);
            position: relative;
            font-size: 0;
            background-color: color-tint($c--monochrome-light-gray, 40%);

            > img {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                max-width: $sz--image-max;
                max-height: $sz--image-max;
                transform: translate(-50%, -50%);
            }
        }
    }

    // Facets
    & {
        &.cui-message-display-content__is-editable {
            cursor: pointer;
        }

        &.cui-message-display-content__manager-is-editing-completed-messages {
            cursor: default;
        }
    }

    // States
}
