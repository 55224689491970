.page.page__type-quick-check-request-cui {
    // Vars

    // Support

    // Module
    & {
        &::before {
            @include bg--cui();
        }

        &::after {
            @include bg--pattern();
        }

        .page--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
        }
    }

    // Facets

    // States
}
