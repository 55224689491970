/// Circle shaped button
///
@mixin btn--bubble($size) {
    min-width: 0;
    border-radius: 50%;

    @if ($size == 'medium' or (not $size)) {
        padding: 8px;
    }

    @if ($size == 'small') {
        padding: 4px;
    }

    .btn--icon {
        margin: 0;
    }
}
