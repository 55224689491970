.gallery-image {
    // Vars
    $sz--gallery-height-large: 440px;

    // Support

    // Module
    & {
        .gallery-image--outer-wrap {
            position: relative;
            display: block;
            width: 100%;
        }

        .gallery-image--inner-wrap {
            @include overlay();
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .gallery-image--image-accessor {
            position: relative;
            display: inline-block;
            max-height: 100%;
            max-width: 100%;
        }
    }

    // Facets
    & {
        &.gallery-image__base {
            .gallery-image--outer-wrap {
                @include mq('<=md') {
                    padding-top: (100% * 2 / 3);
                }

                @include mq('>md') {
                    height: $sz--gallery-height-large;
                }
            }

            @include mq('<=sm') {
                .gallery-image--outer-wrap {
                    cursor: default;
                }
            }
        }
    }

    // States
}
