.diagnosis-table-mobile {
    // Vars
    $sz--padding-y: 24px;
    $sz--padding-x: 24px;
    $c--hover: rgba($c--brand-background-ink, 0.2);

    // Support

    // Module
    & {
        padding: $sz--padding-y 0 0;
        margin: 0;

        .diagnosis-table-mobile-style {
            width: 100%;
            margin-bottom: 48px;

            > tr {
                display: flex;
            }
        }

        .diagnosis-table-mobile-header {
            padding-left: 12px;
            padding-right: 12px;
            align-items: center;
            justify-content: space-between;
            height: 38px;
            background-color: #e2e3e2;
            color: $c--brand-dark-ink;
            font-size: $fz--xx-small;
            font-weight: $fw--normal;

            .display-header {
                display: flex;
            }

            .code-color {
                color: #000000;
                padding-left: 24px;
            }

            //font-weight: $fw--semi-bold;
        }

        .diagnosis-table-mobile-title {
            display: block;
            align-items: center;
            font-size: $fz--xx-small;
            font-weight: $fw--normal;
            line-height: 1.25;
            color: #00629b;
            padding-left: 12px;
            margin-top: 7px;
            margin-bottom: 4px;
        }

        .diagnosis-table-mobile-value {
            padding-left: 12px;
            align-items: center;
            height: fit-content;
            font-size: 13px;
            border-bottom: 1px solid $c--monochrome-light-gray;
            padding-bottom: 7px;
        }

        .diagnosis-table-mobile--info-button {
            padding-left: 16px;
            color: $c--brand-dark-ink;
            cursor: pointer;
        }


        .diagnosis-table-mobile--col {
            &.diagnosis-table-mobile--col__td {
                align-items: center;
                padding-left: 7px;
                border-bottom: 1px solid $c--monochrome-light-gray;
                padding-bottom: 4px;


                .confidence-level-field {
                    .form-field--row {
                        margin: 0;
                    }

                    .form-field--box {
                        display: none;
                        visibility: hidden;
                    }

                    .form-field--label {
                        padding: 6px;
                    }

                    .form-field--input-container {
                        padding-right: 5px;
                        padding-left: 5px;
                    }

                    input:checked ~ label {
                        background-color: $c--brand-dark-ink;
                        color: $c--monochrome-white;
                        border: 1px solid $c--brand-dark-ink;
                    }

                    label {
                        border: 1px solid;
                        text-align: center;
                    }
                }
            }
        }

        .diagnosis-table-mobile--delete-button {
            color: $c--brand-dark-ink;
            cursor: pointer;
        }

        .diagnosis-table-mobile--empty-message-wrap {
            text-align: center;
        }

        .diagnosis-table-mobile--empty-message {
            @include type--copy-xx-small();
            color: $c--monochrome-gray;
        }

        .diagnosis-table-mobile--info-icon {
            //this is used, so that the click event from the button works correctly on GTM (even if you click on the SVG inside of the button)
            pointer-events: none;
        }
    }
}
