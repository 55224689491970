.table-col.table-col__status {
    // Vars
    $sz--status-area: 20px;
    $sz--status-icon: 8px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;

        .table-col--status-area {
            position: relative;
            width: $sz--status-area;
            height: $sz--status-area;
        }

        .table-col--status-icon {
            @include overlay();

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $sz--status-icon;
                height: $sz--status-icon;
                border-radius: $sz--status-icon / 2;
            }
        }
    }

    // Facets
    & {
        &.table-col__success {
            .table-col--status-icon::after {
                background-color: $c--signal-success;
            }
        }

        &.table-col__warning {
            .table-col--status-icon::after {
                background-color: $c--signal-warning;
            }
        }

        &.table-col__error {
            .table-col--status-icon::after {
                background-color: $c--signal-error;
            }
        }
    }

    // States
}
