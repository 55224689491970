/// Medium
///
@mixin btn--size-medium() {
    padding: 10px 20px 8px;
    font-size: $fz--medium;
}

/// Small
///
@mixin btn--size-small() {
    padding: 6px 10px 4px;
    font-size: $fz--small;
}

/// X-Small
///
@mixin btn--size-x-small() {
    padding: 2px 0;
    font-size: $fz--xx-small;
}
