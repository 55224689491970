.report-form-btn {
    // Vars

    // Support

    // Module
    & {
        .report-form-btn--text.report-form-btn--text {
            @include type--copy-xx-small;
            transform: none;
        }
    }

    // Facets

    // States
}
