.page.page__type-doctor-detail {
    // Vars

    // Support

    // Module
    & {
        .page--main {
            z-index: 2;

            @include mq('>#{$bp--mobile}') {
                margin-bottom: 60px;
            }

            @include mq('<=#{$bp--mobile}') {
                margin-bottom: 40px;
            }
        }

        .page--footer {
            z-index: 1;
            position: relative;

            @include mq('<=#{$bp--mobile}') {
                padding-bottom: (50px + (2 * 16px));
            }
        }
    }

    // Facets

    // States
}
