.cui-summary-modal {
    // Vars

    // Support

    // Module
    & {
        // NOTE: This is necessary to prevent form components from bleeding into the mobile-nav
        z-index: 0;
        position: relative;

        .cui-summary-modal--content {
            display: flex;
            flex-flow: column nowrap;
        }

        .cui-summary-modal--title {
            color: $c--brand-ink;
        }

        .cui-summary-modal--separator {
            color: $c--brand-ink;
            margin: 12px 0 16px;
        }

        .cui-summary-modal--row {
            justify-content: center;
        }

        .cui-summary-modal--col {
            @include grid--span((ratio: 5/12, mq: '>md'));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 8/12, mq: 'sm'));
            @include grid--span((ratio: 1/1, mq: 'xs'));
        }

        .cui-summary-modal--content {
            @include mq('>#{$bp--mobile}') {
                padding: (100px + $sz--cui-progress-height) 0 100px;
            }

            @include mq('<=#{$bp--mobile}') {
                padding: (60px + $sz--cui-progress-height) 0 60px;
            }
        }

        .cui-summary-modal--intro {
            margin-bottom: 32px;
        }

        .cui-summary-modal--definition-list {
            margin-bottom: 32px;
        }

        .cui-summary-modal--edit-btn {
            align-self: flex-end;

            @include mq('>#{$bp--mobile}') {
                margin-bottom: 120px;
            }

            @include mq('<=#{$bp--mobile}') {
                margin-bottom: 80px;
            }
        }

        .cui-summary-modal--payment {
            position: relative;
            margin-bottom: 24px;
        }

        .cui-summary-modal--note {
            @include type--copy-xx-small;
            margin-top: 45px;
            display: block;
            color: $c--monochrome-gray;
            line-height: 1.7;
        }
    }

    // Facets

    // States
}
