.page.page__type-doctors {
    // Vars

    // Support

    // Module
    & {
        .page--main {
            @include bg--title;
        }
    }

    // Facets

    // States
}
