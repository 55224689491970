.toast-modal {
    // Vars

    // Support

    // Module
    & {
        .toast-modal--container.toast-modal--container {
            max-width: $sz--confirm-modal-width;
            overflow: visible;
        }

        .toast-modal--content {
            pointer-events: auto;
            box-shadow: 0 0 64px 0 rgba($c--monochrome-dark-gray, 0.2);
            border-radius: $sz--border-radius-base;
        }

        .toast-modal--header {
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            border-top-left-radius: $sz--border-radius-base;
            border-top-right-radius: $sz--border-radius-base;
            border-bottom: 1px solid $c--monochrome-light-gray;
        }

        .toast-modal--body {
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            border-bottom-left-radius: $sz--border-radius-base;
            border-bottom-right-radius: $sz--border-radius-base;
            text-align: center;
        }

        .toast-modal--title {
            color: $c--brand-ink;
            text-align: center;
        }

        .toast-modal--description {
            @include type--copy;
        }
    }

    // Facets

    // States
}
