.image-list {
    // Vars
    $sz--image-list-item-row-size: 220px;

    // Support

    // Module
    & {
        .image-list--item {
            @include grid--span((ratio: 1/3, mq: '>sm'));
            display: flex;
            overflow: hidden;
        }
    }

    // Facets
    & {
        &.image-list__doctor-list {
            .image-list--item {
                @include grid--span((ratio: 1/4, mq: '>lg'));
                @include grid--span((ratio: 1/4, mq: 'lg'));
                @include grid--span((ratio: 1/2, mq: ('>xs' '<=md')));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
            }
        }

        &.image-list__testimonials {
            .image-list--item {
                @include grid--span((ratio: 1/2, mq: '>sm'));
                @include grid--span((ratio: 1/1, mq: '<=sm'));

                @include at-root(html, '.ie11') {
                    display: block;
                }

                @include at-root(html, '.ie10') {
                    display: block;
                }
            }
        }

        &.image-list__news {
            .image-list--item {
                @include grid--span((ratio: 1/3, mq: '>md'));
                @include grid--span((ratio: 1/2, mq: 'md'));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
                overflow: visible;
            }
        }

        &.image-list__partners {
            .image-list--item {
                @include grid--span((ratio: 1/4, mq: '>md'));
                @include grid--span((ratio: 1/2, mq: '<=md'));
            }
        }
    }

    // States
    & {
        // As row
        &.image-list__as-row {
            .image-list--break-out {
                position: relative;
                left: 50%;
                width: 100vw;
                overflow: hidden;
            }

            .image-list--container {
                @include ios-native-scrolling(x);
                font-size: 0;
            }

            .image-list--items {
                display: inline-flex;
                flex-wrap: nowrap;
            }

            .image-list--item {
                font-size: $fz--medium;

                @include mq('<=sm') {
                    flex: 0 0 $sz--image-list-item-row-size;
                    width: $sz--image-list-item-row-size;
                }
            }

            @each $value in $grid--cms-spacing {
                $mq: map-get($value, 'mq');
                $gutter: map-get($value, 'gutter');
                $edge: map-get($value, 'edge');

                @include mq($mq) {
                    .image-list--item ~ .image-list--item {
                        margin-left: nth($gutter, 2);
                    }

                    //.image-list--rail {}

                    .image-list--items {
                        padding-left: $edge;
                        padding-right: $edge;
                    }
                }
            }
        }
    }
}
