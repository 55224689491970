.report-form {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'snippet-actions', cols: ('snippet-action')));
        @include grid--space((gutter: (2px 2px), row: 'snippet-actions', cols: ('snippet-action')));

        .report-form--snippet-actions-wrap {
            margin-top: 4px;
        }

        .report-form--snippet-actions {
            flex-flow: column nowrap;
        }

        .report-form--snippet-action {
            display: flex;
        }

        .report-form--errors {
            padding: 24px;
        }
    }

    // Facets

    // States
}
