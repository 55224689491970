.cui-summary-form-element {
    // Vars

    // Support

    // Module
    & {
        .cui-summary-form-element--voucher {
            margin-top: -16px;
            text-align: right;
        }

        .cui-summary-form-element--voucher-btn {
            @include type--copy-x-small;
            cursor: pointer;
        }
    }

    // Facets

    // States
}
