/// Form field facet
///
@mixin form-field--as-button() {
    // Vars
    $sz--input-padding-x: 12px;
    $sz--input-padding-y: 10px;

    $sz--actions-icon-margin: 10px;
    $sz--actions-icon-offset: 0;
    $sz--actions-icon-width: 16px;

    // Support

    // Fields
    & {
        @include form-field--as-button-all();

        &.form-field__input {
            @include form-field--as-button-input(
                $sz--padding-top: $sz--input-padding-y,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-y
            );

            &.form-field__select {
                @include form-field--as-button-select();
                @include form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-y,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-y
                );
            }
        }
    }

    // Addons
}
