&.carousel__gallery {
    $sz--arrow: 48px;

    & {
        @include grid--space((gutter: (0), row: 'items', cols: ('item')));
    }

    .carousel--root {
        margin: 0 ($sz--arrow / 2);

        @include mq('<=sm') {
            margin: 0;
        }
    }

    .carousel--arrows {
        @include overlay();
        pointer-events: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .carousel--arrow {
        @include btn--pagination-arrow();
        @include btn--size-medium();
        @include btn--square('medium');
        pointer-events: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row nowrap;
        width: $sz--arrow;
        height: $sz--arrow;
        padding: 0;
        border: none;
        color: $c--monochrome-black;

        &:not([disabled]) {
            cursor: pointer;
        }

        &.carousel--arrow__prev {
            @include mq('<=sm') {
                transform: translateX(-50%);
            }
        }

        &.carousel--arrow__next {
            @include mq('<=sm') {
                transform: translateX(50%);
            }
        }
    }
}
