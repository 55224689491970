/// Iterate through a states config object to apply a given style to the compiled selectors
/// @param {Map} $states - A state definition object
///
@mixin btn--apply-states($states: ()) {
    $keys: map-keys($states);
    $has-keys: length($keys) > 0;

    @if ($has-keys) {
        $key: nth($keys, 1);
        $value: map-get($states, $key);
        $next: map-remove($states, $key);

        @include btn--apply-state($key, $value) {
            @include btn--apply-states($next) {
                @content;
            }
        }
    } @else {
        @content;
    }
}

/// Call a style mixin according to a given key/value pair
/// @param {string} $key - The state name
/// @param {boolean} $value - The state value
///
@mixin btn--apply-state($key, $value) {
    @if ($key == 'focus') {
        @include btn--state-focus($value) {
            @content;
        }
    }

    @if ($key == 'active') {
        @include btn--state-active($value) {
            @content;
        }
    }

    @if ($key == 'active-route') {
        @include btn--state-active-route($value) {
            @content;
        }
    }

    @if ($key == 'disabled') {
        @include btn--state-disabled($value) {
            @content;
        }
    }
}

/// Target both the input field and the label
///
@mixin btn--shadowed-target-both() {
    &,
    &::after {
        @content;
    }
}

@mixin btn--shadowed($sz--shadow, $c--shadow, $sz--border) {
    @include btn--apply-states(()) {
        @include btn--shadowed-base($sz--shadow, $c--shadow, $sz--border);
    }

    &:not(.u-reset) {
        @include btn--apply-states(('disabled': true)) {
            @include btn--shadowed-active($sz--border);
        }

        @include btn--apply-states(('active': true)) {
            @include btn--shadowed-active($sz--border);
        }

        @include btn--apply-states(('active-route': true)) {
            @include btn--shadowed-active($sz--border);
        }
    }
}

/// Shadow base styles
///
@mixin btn--shadowed-base($sz--shadow, $c--shadow, $sz--border) {
    z-index: 1;
    position: relative;
    margin: $sz--shadow 0 0 0;
    transform: translate(0, -$sz--shadow);
    backface-visibility: hidden;

    &, &::before, &::after {
        transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);
    }

    &::after,
    &::before {
        @include btn--base();
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        padding: $sz--border;
        border: $sz--border;
    }

    &::after {
        transform: translate(-$sz--border, -$sz--border);
    }

    &::before {
        background-color: $c--shadow;
        transform: translate(-$sz--border, -$sz--border + $sz--shadow);
    }
}

/// Shadow active styles
///
@mixin btn--shadowed-active($sz--border) {
    transform: translate(0);

    &::before, &::after {
        transform: translate(-$sz--border, -$sz--border);
    }

    &::before {
        opacity: 0;
    }
}
