.contact-request-form {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: (8px 8px), row: 'items', cols: ('item')));
        @include cms--padded-plugin();

        .contact-request-form--title {
            margin-bottom: 16px;
            color: $c--brand-ink;
        }

        .contact-request-form--col {
            @include grid--span((ratio: 5/12, mq: '>lg'));
            @include grid--span((ratio: 6/12, mq: ('<=lg' '>md')));
            @include grid--span((ratio: 8/12, mq: 'md'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
