.trs-tooltip-fade {
    // Vars
    $sz--offset: 8px;
    $origin-x-values: (left, center, right);
    $origin-y-values: (top, center, bottom);
    $origin-x-transform-modifiers: (left: $sz--offset, center: 0, right: -$sz--offset);
    $origin-y-transform-modifiers: (top: $sz--offset, center: 0, bottom: -$sz--offset);

    // Support

    // Module

    // Facets

    // States
    & {
        &-left-top, &-left-center, &-left-bottom,
        &-center-top, &-center-center, &-center-bottom,
        &-right-top, &-right-center, &-right-bottom {
            &-enter-active, &-leave-active {
                transition: transition-props($trs--base-speed $trs--base-fn, opacity, transform);
            }
        }

        &-left-top, &-left-center, &-left-bottom,
        &-center-top, &-center-center, &-center-bottom,
        &-right-top, &-right-center, &-right-bottom {
            &-enter, &-leave-to {
                opacity: 0;
            }
        }

        @each $origin-x-value in $origin-x-values {
            $modifier-x: map-get($origin-x-transform-modifiers, $origin-x-value);

            @each $origin-y-value in $origin-y-values {
                $modifier-y: map-get($origin-y-transform-modifiers, $origin-y-value);

                &-#{$origin-x-value}-#{$origin-y-value} {
                    &-enter, &-leave-to {
                        transform: translate($modifier-x, $modifier-y);
                    }
                }
            }
        }
    }
}
