.body-regions-modal {
    // Vars
    $c--shadow: #333333;
    $sz--bottom-bar-height-large: 100px;
    $sz--bottom-bar-height-small: 84px;

    // Support

    // Module
    & {
        @include grid--root((row: 'buttons', cols: ('button')));
        @include grid--space((gutter: 24px, row: 'buttons', cols: ('button')));

        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: $sz--bottom-bar-height-large;
        background-color: $c--monochrome-white;

        @include mq('<=sm') {
            padding-bottom: $sz--bottom-bar-height-small;
        }

        // DANGER ZONE - For Safari hacks, see at the bottom of this file

        .body-regions-modal--stage {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .body-regions-modal--bottom-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: $sz--bottom-bar-height-large;
            padding: 0 24px;
            box-shadow: 0 -2px 8px 0 rgba($c--monochrome-dark-gray, 0.3);

            @include mq('<=sm') {
                height: $sz--bottom-bar-height-small;
            }
        }

        .body-regions-modal--navigation {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 100%;
        }

        .body-regions-modal--navigation-wrapper {
            height: 100%;
        }

        .body-regions-modal--button.body-regions-modal--button__cancel {
            @include mq('<=sm') {
                display: none;
            }
        }

        .body-regions-modal--close {
            z-index: 1;
            position: absolute;
            top: 16px;
            right: 16px;

            @include mq('>sm') {
                display: none;
            }
        }

        .body-regions-modal--close-button {
            display: block;
            padding: 16px;
            color: $c--brand-ink;
            cursor: pointer;
            transition: color $trs--base;
            box-shadow: 0 2px 8px 0 rgba($c--shadow, 0.2);

            @include has-focus(true) {
                color: $c--brand-light-ink;
            }
        }

        .body-regions-modal--close-icon {
            pointer-events: none;
        }

        .body-regions-modal--link {
            height: $sz--bottom-bar-height-large;

            @include mq('<=sm') {
                height: $sz--bottom-bar-height-small;
            }
        }

        .body-regions-modal--desktop-nav {
            @include mq('<=sm') {
                display: none;
            }
        }

        .body-regions-modal--mobile-nav {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            padding-right: 12px;

            @include mq('>sm') {
                display: none;
            }
        }
    }

    // Facets

    // States
}

/* stylelint-disable */
/// DANGER ZONE
/// These are dirty hacks to fix display bugs in Safari 10.0 and 10.1 respectively
/// @link https://browserstrangeness.bitbucket.io/css_hacks.html
///

// Safari 10.0
_::-webkit-:host:not(:root:root), .body-regions-modal {
    height: 100vh;
}

// Safari 10.1
@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        .body-regions-modal {
            height: 100vh;
        }
    }
}

// Safari 9
@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
    .body-regions-modal {
        height: 100vh;
    }
}
/* stylelint-enable */
