.health-insurance-overlay {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: calc(436px + 3.2rem);
            padding: 1.6rem;
            margin: 0 auto;

            min-height: 500px;
            height: 700px;

            @media (max-height: 767px) {
                height: calc(100vh - 1.6em);
            }
        }
    }
    // Facets

    // States
}
