&.grid__landingpage {
    // Vars
    $grid--cms-container-sizes: $grid--base-container-sizes;
    $grid--cms-spacing: (
        (mq: 'xs', gutter: (30px 16px), edge: 32px),
        (mq: 'sm', gutter: (30px 16px), edge: 32px),
        (mq: 'md', gutter: (30px 24px), edge: 24px),
        (mq: 'lg', gutter: (30px 32px), edge: 32px),
        (mq: 'xl', gutter: (30px 36px), edge: 36px),
    ) !global;

    // Support

    // Module
    & {
        @include grid--root();

        @each $value in $grid--cms-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--cms-spacing {
            @include grid--space($value);
        }

        @each $value in $grid--cms-spacing {
            $mq: map-get($value, 'mq');
            $gutter: map-get($value, 'gutter');

            @include mq($mq) {
                .grid--col {
                    column-gap: nth($gutter, 2);
                }
            }
        }
    }

    // Facets

    // States
}
