.nav-link {
    // Vars
    $sz--item-border-height: 3px;

    // Support

    // Module
    & {
        .nav-link--wrapper {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: $sz--item-border-height;
                background-color: $c--brand-ink;

                opacity: 0;
                transform: translateY(-$sz--item-border-height);
                transition: transition-props($trs--base, opacity, transform);
            }
        }

        &.u-router-link-active {
            color: $c--brand-ink;
            cursor: default;

            .nav-link--wrapper {
                &::after {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    // Facets
    & {
        &.nav-link__mobile {
            .nav-link--wrapper {
                &::after {
                    top: auto;
                    bottom: 0;
                }
            }
        }
    }

    // States
}
