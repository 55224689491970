.health-insurance-overlay-public {
    // Module
    & {
        & {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        .health-insurance-overlay-public--box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: darken($c--monochrome-light-gray, 1%);
            padding: 7px;
        }

        .health-insurance-overlay-public--info {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            justify-content: center;
            padding-left: 13px;
            padding-right: 13px;
            align-items: center;
            overflow-wrap: anywhere;
        }

        .health-insurance-overlay-public--insurance-name {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            padding-bottom: 10px;
            color: #00629b;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }

        .health-insurance-overlay-public--insurance-description {
            font-size: 16px;
            font-style: normal;
            text-align: center;

            @media (max-width: 400px) {
                font-size: 14px;
            }
        }

        .health-insurance-overlay-public--item-media {
            padding-bottom: 10px;
            max-width: 60px;
        }
    }
}
