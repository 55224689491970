.content-button {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();
    }

    // Facets
    & {
        &.content-button__is-centered {
            .content-button--wrap {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    // States
}
