/// Field styles
///
@mixin form-field--primary-image-widget-text-area() {
    // Vars

    // Support

    // Module
    & {
        .form-field--input {
            padding-top: 0;
        }
    }

    // States
}
