.trs-page-fade {
    // Vars
    $trs--page-fade-speed: 200ms;
    $trs--page-fade-fn: ease('in-out-material');
    $trs--page-fade: $trs--page-fade-speed $trs--page-fade-fn;

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--page-fade, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
